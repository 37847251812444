import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  UpdateUserDto,
  updateUserDtoValidationSchema,
} from '../../../app/dto/user/update-user.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { allRoles, Role } from '../../../app/enums/role.enum';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { usersService } from '../../../bootstrap/di';
import {
  borderContainedButtonSx,
  buttonLinkSx,
  inputSx,
  labelSx,
  primaryButtonSx,
  selectSx,
} from '../../../sx';
import {
  confirmationFactory,
  ConfirmComponent,
  ConfirmComponentProps,
} from '../../components/shared/ConfirmComponent';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const EditUserPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [confirmation, setConfirmation] = useState(
    null as unknown as ConfirmComponentProps,
  );
  const [updateUserDto, setUpdateUserDto] = useState({
    email: '',
    name: '',
    role: Role.client,
    language: Language.pl,
  });

  const userId = searchParams.get('id');

  const deleteUser = async (userId: string) => {
    setLoading(true);

    const result = await usersService.delete(userId);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    navigate('/users');
  };

  const updateUser = async () => {
    setLoading(true);

    const validationError = Validator.validate<UpdateUserDto>(
      updateUserDto,
      updateUserDtoValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await usersService.update(userId as string, updateUserDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  const loadUser = async () => {
    const user = await usersService.getOne(userId as string);

    if (!user) {
      navigate('/404', { replace: true });
      return;
    }

    setUpdateUserDto({
      name: user.name,
      email: user.email,
      role: user.role,
      language: user.language,
    });
  };

  useEffect(() => {
    if (!userId) {
      navigate('/404', { replace: true });
    }

    loadUser();
  }, []);

  return (
    <>
      {updateUserDto.email && (
        <>
          <ToastComponent
            message={toast.message}
            type={toast.type as AlertColor}
            fire={toast.fire}
            id={toast.id}
          ></ToastComponent>
          {confirmation && (
            <ConfirmComponent
              message={confirmation.message}
              callbackFn={confirmation.callbackFn}
              fire={confirmation.fire}
              id={confirmation.id}
              callbackParams={confirmation.callbackParams}
            />
          )}
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
              <Link underline="hover" color="inherit" href="/">
                Aleso
              </Link>
              <Link underline="hover" color="inherit" href="/">
                {t({ phrase: 'Dashboard' })}
              </Link>
              <Link underline="hover" color="inherit" href="/users">
                {t({ phrase: 'Users' })}
              </Link>
              <Link underline="hover" color="text.primary" aria-current="page">
                {updateUserDto.name}
              </Link>
            </Breadcrumbs>
          </div>

          <Grid className='zoomable'>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
              <LoadingButton
                className="contained-button"
                sx={{
                  ...buttonLinkSx,
                  ...borderContainedButtonSx,
                }}
                loading={loading}
                onClick={() => {
                  setConfirmation(
                    confirmationFactory(
                      deleteUser,
                      true,
                      t({
                        phrase:
                          'Are you sure you want to continue? This operation cannot be undone.',
                      }),
                      [userId],
                    ),
                  );
                }}
              >
                {t({ phrase: 'Delete' })} <Delete />
              </LoadingButton>
            </Grid>
            <Grid item xs={12} className='zoomable'>
              <DashboardSectionComponent
                title={t({ phrase: 'Edit user' })}
                minHeight={100}
              >
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    label={t({ phrase: 'Name' })}
                    type="text"
                    id="name"
                    sx={inputSx}
                    autoFocus
                    value={updateUserDto.name}
                    onChange={(e) =>
                      setUpdateUserDto({
                        ...updateUserDto,
                        name: e.target.value,
                      })
                    }
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t({ phrase: 'Email' })}
                    name="email"
                    autoComplete="email"
                    sx={inputSx}
                    value={updateUserDto.email}
                    onChange={(e) =>
                      setUpdateUserDto({
                        ...updateUserDto,
                        email: e.target.value,
                      })
                    }
                  />

                  <FormControl fullWidth required margin="normal">
                    <InputLabel id="language-label" sx={labelSx}>
                      {t({ phrase: 'Language' })}
                    </InputLabel>
                    <Select
                      labelId="language-label"
                      id="language"
                      label={t({ phrase: 'language' })}
                      value={updateUserDto.language}
                      onChange={(e: SelectChangeEvent) =>
                        setUpdateUserDto({
                          ...updateUserDto,
                          language: e.target.value as Language,
                        })
                      }
                      sx={selectSx}
                    >
                      {allowedLanguages.map((language: Language) => (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth required margin="normal">
                    <InputLabel id="role-label" sx={labelSx}>
                      {t({ phrase: 'Role' })}
                    </InputLabel>
                    <Select
                      labelId="role-label"
                      id="role"
                      label={t({ phrase: 'Role' })}
                      value={updateUserDto.role}
                      onChange={(e: SelectChangeEvent) =>
                        setUpdateUserDto({
                          ...updateUserDto,
                          role: e.target.value as Role,
                        })
                      }
                      sx={selectSx}
                    >
                      {allRoles.map((role: Role) => (
                        <MenuItem key={role} value={role}>
                          {t({ phrase: role })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ ...primaryButtonSx, marginTop: '5px', mb: 2 }}
                    loading={loading}
                    onClick={updateUser}
                  >
                    {t({ phrase: 'Save' })}
                  </LoadingButton>
                  <Link sx={{ textAlign: 'right' }} href="/users">
                    {t({ phrase: 'Go back' })}
                  </Link>
                </>
              </DashboardSectionComponent>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
