import { Breadcrumbs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Language } from '../../../app/enums/language.enum';
import { languageService } from '../../../bootstrap/di';

const langTypographyStyles = {
  cursor: 'pointer',
};

export const LanguageComponent = () => {
  const [language, setLanguage] = useState(languageService.getLanguage());

  const updateLanguageSelection = (selectedLang: Language) => {
    if (selectedLang === language) {
      return;
    }

    languageService.setLanguage(selectedLang);
    setLanguage(selectedLang);
    window.location.reload();
  };

  const getLangTypographyDecoration = (typographyLang: Language) => {
    return typographyLang === language ? 'underline' : 'none';
  };

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          sx={{
            ...langTypographyStyles,
            textDecoration: getLangTypographyDecoration(Language.pl),
          }}
          onClick={() => updateLanguageSelection(Language.pl)}
          color="text.primary"
        >
          PL
        </Typography>
        <Typography
          sx={{
            ...langTypographyStyles,
            textDecoration: getLangTypographyDecoration(Language.de),
          }}
          onClick={() => updateLanguageSelection(Language.de)}
          color="text.primary"
        >
          DE
        </Typography>
        <Typography
          sx={{
            ...langTypographyStyles,
            textDecoration: getLangTypographyDecoration(Language.en),
          }}
          onClick={() => updateLanguageSelection(Language.en)}
          color="text.primary"
        >
          EN
        </Typography>
      </Breadcrumbs>
    </div>
  );
};
