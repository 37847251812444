import { UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AlertColor, Breadcrumbs, Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  UploadProjectVersionDto,
  uploadProjectVersionValidationSchema,
} from '../../../app/dto/project/upload-project-version.dto';
import { Project } from '../../../app/models/project';
import { convertFileToBase64 } from '../../../app/utils/base64-converter';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { projectsService } from '../../../bootstrap/di';
import { primaryButtonSx } from '../../../sx';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import { Loading } from '../../components/shared/Loading';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const UploadProjectVersionPage = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState<Project | null>(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [uploadProjectVersionDto, setUploadProjectVersionDto] = useState({
    name: '',
    imageBase64: '',
    mime: '',
  });

  const projectId = searchParams.get('id');

  const storeFile = async (file: any) => {
    const { name, type } = file;
    const imageBase64 = (await convertFileToBase64(file)) as unknown as string;
    setUploadProjectVersionDto({
      name,
      mime: type,
      imageBase64,
    });
  };

  const uploadProjectVersion = async () => {
    setLoading(true);

    const validationError = Validator.validate<UploadProjectVersionDto>(
      uploadProjectVersionDto,
      uploadProjectVersionValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await projectsService.uploadVersion(
      project?.id as string,
      uploadProjectVersionDto,
    );

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setUploadProjectVersionDto({
      name: '',
      imageBase64: '',
      mime: '',
    });

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  const loadProject = async () => {
    const loaded = await projectsService.getOne(projectId as string);

    if (!loaded) {
      navigate('/404', { replace: true });
      return;
    }

    setProject(loaded);
  };

  useEffect(() => {
    if (!projectId) {
      navigate('/404', { replace: true });
    }

    loadProject();
  }, []);

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/projects">
            {t({ phrase: 'Projects' })}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/projects/details?id=${project?.id}`}
          >
            {project?.name}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Upload version' })}
          </Link>
        </Breadcrumbs>
      </div>
      {project ? (
        <Grid container spacing={3} className='zoomable'>
          <Grid item xs={12}>
            <DashboardSectionComponent
              title={t({ phrase: 'Upload version' })}
              minHeight={100}
            >
              <>
                <div className="file-input-group">
                  <label htmlFor="projectVersionFile">
                    {t({ phrase: 'Select image' })}
                    <UploadFile />
                  </label>
                  <input
                    id="projectVersionFile"
                    className="file-input"
                    type="file"
                    name="projectVersionFile"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        storeFile(e.target.files[0]);
                      } else {
                        setUploadProjectVersionDto({
                          name: '',
                          imageBase64: '',
                          mime: '',
                        });
                      }
                    }}
                    multiple={false}
                  />
                  <p className="file-name">
                    {uploadProjectVersionDto.name
                      ? `${t({ phrase: 'Selected file' })}: ${
                          uploadProjectVersionDto.name
                        }`
                      : ''}
                  </p>
                  <br />
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ ...primaryButtonSx, marginTop: '5px', mb: 2 }}
                    loading={loading}
                    onClick={uploadProjectVersion}
                  >
                    {t({ phrase: 'Save' })}
                  </LoadingButton>
                  <Link
                    sx={{ textAlign: 'right' }}
                    href={`/projects/details?id=${project.id}`}
                  >
                    {t({ phrase: 'Go back' })}
                  </Link>
                </div>
              </>
            </DashboardSectionComponent>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};
