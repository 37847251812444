import { Breadcrumbs, Grid, Link } from '@mui/material';
import React from 'react';
import { activeRoles, Role } from '../../../app/enums/role.enum';
import { User } from '../../../app/models/user';
import { t } from '../../../app/utils/translator';
import { authService } from '../../../bootstrap/di';
import { AccountInfoComponent } from '../../components/account/AccountInfoComponent';
import { ActivateAccountComponent } from '../../components/account/ActivateAccountComponent';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import { ProjectListComponent } from '../../components/project/ProjectListComponent';

export const DashboardPage = () => {
  const loggedUser = authService.getLoggedUser() as User;

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Dashboard' })}
          </Link>
        </Breadcrumbs>
      </div>
      <Grid container spacing={3} className='zoomable'>
        {activeRoles.includes(loggedUser.role) && (
          <>
            <Grid item xs={12} md={8} lg={9}>
              <DashboardSectionComponent
                title={t({ phrase: 'Last projects' })}
                action={{
                  text: t({ phrase: 'Go to projects' }),
                  href: 'projects',
                }}
                minHeight={400}
              >
                <ProjectListComponent showControls={false} perPage={2} />
              </DashboardSectionComponent>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <DashboardSectionComponent
                title={t({ phrase: 'Your account' })}
                action={{
                  text: t({ phrase: 'Edit' }),
                  href: '/account',
                }}
                minHeight={400}
              >
                <AccountInfoComponent user={loggedUser} />
              </DashboardSectionComponent>
            </Grid>
          </>
        )}
        {loggedUser.role === Role.inactive && (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <DashboardSectionComponent
                title={t({
                  phrase: 'Welcome :name!',
                  props: { ':name': loggedUser.name as string },
                })}
              >
                <ActivateAccountComponent />
              </DashboardSectionComponent>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
