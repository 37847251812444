import { ArrowUpward, Delete, Translate } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Role } from '../../../app/enums/role.enum';
import { ChatMessage } from '../../../app/models/chat-message';
import { User } from '../../../app/models/user';
import { isAdmin, isEmployeeRole } from '../../../app/utils/role-checker';
import { t } from '../../../app/utils/translator';
import { authService } from '../../../bootstrap/di';
import { shoutboxSlug } from './ChatComponent';

type ChatMessagesComponentProps = {
  messages: ChatMessage[];
  loading: boolean;
  pageBlocked: boolean;
  onLoadMore: CallableFunction;
  onTranslate?: CallableFunction;
  onDelete?: CallableFunction;
  chatRoomSlug?: string;
};

export const ChatMessagesComponent = ({
  messages,
  loading,
  pageBlocked,
  onLoadMore,
  onTranslate,
  onDelete,
  chatRoomSlug,
}: ChatMessagesComponentProps) => {
  const loggedUser = authService.getLoggedUser() as User;
  return (
    <>
      <List sx={{ width: '100%' }}>
        {!pageBlocked && messages.length > 9 && (
          <ListItem
            disableGutters={true}
            key="loadmore"
            alignItems="flex-start"
            onClick={() => onLoadMore()}
          >
            <LoadingButton loading={loading} fullWidth>
              {t({ phrase: 'load more' })} <ArrowUpward />
            </LoadingButton>
          </ListItem>
        )}
        {messages.map((message: ChatMessage) => (
          <ListItem
            sx={{ background: `${loggedUser.id !== message.userId ? '#FD8D8D' : '#BCA7A7'}`, marginTop: '10px', paddingX: '10px' }}
            disableGutters={true}
            key={message.id}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <Avatar alt={message.user}>
                {message.user ? message.user.slice(0, 2) : ''}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={message.user}
              secondary={
                <React.Fragment>
                  {message.createdAt}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <strong>{message.content}</strong>
                    {chatRoomSlug === shoutboxSlug &&
                      isAdmin(authService.getLoggedUser()?.role as Role) &&
                      onDelete && (
                        <LoadingButton
                          sx={{}}
                          title={t({ phrase: 'delete' })}
                          loading={loading}
                          onClick={() => onDelete(message.id)}
                        >
                          <Delete />
                        </LoadingButton>
                    )}
                    {chatRoomSlug !== shoutboxSlug &&
                      onTranslate &&
                      isEmployeeRole(
                        authService.getLoggedUser()?.role as Role,
                      ) && (
                        <LoadingButton
                          title={t({ phrase: 'translate' })}
                          loading={loading}
                          onClick={() => onTranslate(message.id)}
                        >
                          <Translate />
                        </LoadingButton>
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
