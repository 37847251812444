export const trimDataTypeFromBase64 = (base64: string): string => {
  return base64.split(',')[1];
};

export const convertFileToBase64 = async (file: any) => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(trimDataTypeFromBase64(fileReader.result as string));
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
