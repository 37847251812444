import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { DeleteShoutboxMessagesDto } from '../dto/chat/delete-shoutbox-messages.dto';
import { Result } from '../models/result';

export class ShoutboxService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async deleteMessage(messageId: string): Promise<Result<void>> {
    try {
      const response = await this.apiClient.delete<BackendResponse<void>>(
        `chat/shoutbox/messages/${messageId}`,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async deleteMessagesByDate(
    dto: DeleteShoutboxMessagesDto,
  ): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        'chat/shoutbox/delete-messages-by-date',
        dto,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }
}
