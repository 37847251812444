import { LocalStorageKey } from '../../infrastructure/local-storage/storage/local-storage-key.enum';
import { LocalStorageService } from '../../infrastructure/local-storage/storage/local-storage.service';

export class WcagService {
  constructor(private readonly localStorageService: LocalStorageService) {}

  isContrastMode(): boolean {
    return !!this.localStorageService.get(LocalStorageKey.contrast);
  }

  toggleContrastMode() {
    this.localStorageService.persist(
      LocalStorageKey.contrast,
      !this.isContrastMode(),
    );
  }

  isFontSizeIncrease(): boolean {
    return !!this.localStorageService.get(LocalStorageKey.increasedFontSize);
  }

  toggleIncreasedFontSize() {
    this.localStorageService.persist(
      LocalStorageKey.increasedFontSize,
      !this.isFontSizeIncrease(),
    );
  }
}
