import { ArrowLeftSharp, LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  ApplyNewPasswordDto,
  applyNewPasswordValidationSchema,
} from '../../../app/dto/account/apply-new-password.dto';
import {
  ResetPasswordDto,
  resetPasswordValidationSchema,
} from '../../../app/dto/account/reset-password.dto';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { accountService } from '../../../bootstrap/di';
import {
  bgPrimarySx,
  buttonLinkSx,
  inputSx,
  linkSx,
  primaryButtonSx,
} from '../../../sx';
import { LanguageComponent } from '../../components/shared/LanguageComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

enum Mode {
  reset,
  apply,
  info,
}

export const ResetPasswordPage = () => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(Mode.reset);
  const [resetPasswordDto, setResetPasswordDto] = useState({ email: '' });
  const [applyNewPasswordDto, setApplyNewPasswordDto] = useState({
    email: '',
    otp: '',
    password: '',
    passwordConfirmation: '',
  });

  const resetPassword = async () => {
    setLoading(true);

    const validationError = Validator.validate<ResetPasswordDto>(
      resetPasswordDto,
      resetPasswordValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await accountService.resetPassword(resetPasswordDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(
      toastAlertFactory(
        t({
          phrase:
            "Check your email address. You will receive confirmation code if account with selected email address exist. Fill below field with it's value.",
        }),
        'success',
        true,
      ),
    );

    setApplyNewPasswordDto({
      email: resetPasswordDto.email,
      otp: '',
      password: '',
      passwordConfirmation: '',
    });

    setResetPasswordDto({ email: '' });

    setMode(Mode.apply);

    setLoading(false);
  };

  const applyNewPassword = async () => {
    setLoading(true);

    const validationError = Validator.validate<ApplyNewPasswordDto>(
      applyNewPasswordDto,
      applyNewPasswordValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await accountService.applyNewPassword(applyNewPasswordDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(
      toastAlertFactory(
        t({
          phrase: 'New password has been set. You can now log in using it.',
        }),
        'success',
        true,
      ),
    );

    setApplyNewPasswordDto({
      email: resetPasswordDto.email,
      otp: '',
      password: '',
      passwordConfirmation: '',
    });

    setMode(Mode.info);

    setLoading(false);
  };

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <Avatar sx={{ m: 1, ...bgPrimarySx }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t({ phrase: 'Password reset' })}
      </Typography>
      <LanguageComponent />
      {mode === Mode.info && (
        <>
          <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
            <br />
            {t({
              phrase: 'New password has been set. You can now log in using it.',
            })}
          </Typography>
          <Link href="/" variant="body2" sx={linkSx}>
            <h3>{t({ phrase: 'Go to sign in page to log in' })}</h3>
          </Link>
        </>
      )}

      {mode === Mode.reset && (
        <>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t({ phrase: 'Email Address' })}
              name="email"
              autoComplete="email"
              autoFocus
              sx={inputSx}
              onChange={(e) =>
                setResetPasswordDto({
                  email: e.target.value,
                })
              }
            />

            <LoadingButton
              type="button"
              fullWidth
              variant="contained"
              sx={{ ...primaryButtonSx, mt: 3, mb: 2 }}
              onClick={resetPassword}
              loading={loading}
            >
              {t({ phrase: 'Password reset' })}
            </LoadingButton>
            <Grid container>
              <Grid item xs sx={{ paddingRight: '15px;' }}>
                <Link href="/" variant="body2" sx={linkSx}>
                  {t({ phrase: 'Do you have account? Log in' })}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2" sx={linkSx}>
                  {t({ phrase: "Don't have an account? Sign Up" })}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {mode === Mode.apply && (
        <>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              label={t({ phrase: 'One time password' })}
              name="otp"
              autoComplete="otp"
              onChange={(e) =>
                setApplyNewPasswordDto({
                  ...applyNewPasswordDto,
                  otp: e.target.value,
                })
              }
              sx={inputSx}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t({ phrase: 'Password' })}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) =>
                setApplyNewPasswordDto({
                  ...applyNewPasswordDto,
                  password: e.target.value,
                })
              }
              sx={inputSx}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              label={t({ phrase: 'Password confirmation' })}
              type="password"
              id="passwordConfirmation"
              onChange={(e) =>
                setApplyNewPasswordDto({
                  ...applyNewPasswordDto,
                  passwordConfirmation: e.target.value,
                })
              }
              sx={inputSx}
            />

            <LoadingButton
              type="button"
              fullWidth
              variant="contained"
              sx={{ ...primaryButtonSx, mt: 3, mb: 2 }}
              onClick={applyNewPassword}
              loading={loading}
            >
              {t({ phrase: 'Confirm new password' })}
            </LoadingButton>

            <Grid container>
              <Grid item>
                <Link
                  onClick={() => setMode(Mode.reset)}
                  variant="body2"
                  sx={linkSx}
                >
                  <Button sx={buttonLinkSx}>
                    <ArrowLeftSharp /> {t({ phrase: 'Go back' })}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
