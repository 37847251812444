import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  UpdateProjectDto,
  updateProjectValidationSchema,
} from '../../../app/dto/project/update-project.dto';
import {
  ProjectStatus,
  projectStatuses,
} from '../../../app/enums/project-status.enum';
import { Project } from '../../../app/models/project';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { projectsService } from '../../../bootstrap/di';
import { inputSx, labelSx, primaryButtonSx, selectSx } from '../../../sx';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import { Loading } from '../../components/shared/Loading';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const EditProjectPage = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState<Project | null>(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));

  const projectId = searchParams.get('id');

  const loadProject = async () => {
    const loaded = await projectsService.getOne(projectId as string);

    if (!loaded) {
      navigate('/404', { replace: true });
      return;
    }

    setProject(loaded);
  };

  const saveProject = async () => {
    setLoading(true);

    const dto = {
      name: project?.name,
      status: project?.status,
    } as UpdateProjectDto;

    const validationError = Validator.validate<UpdateProjectDto>(
      dto,
      updateProjectValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await projectsService.update(project?.id as string, dto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  useEffect(() => {
    if (!projectId) {
      navigate('/404', { replace: true });
    }

    loadProject();
  }, []);

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/projects">
            {t({ phrase: 'Projects' })}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/projects/details?id=${project?.id}`}
          >
            {project?.name}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Edit' })}
          </Link>
        </Breadcrumbs>
      </div>

      {project ? (
        <Grid container spacing={3} className='zoomable'>
          <Grid item xs={12}>
            <DashboardSectionComponent
              title={t({ phrase: 'Edit project' })}
              minHeight={100}
            >
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="name"
                  label={t({ phrase: 'Name' })}
                  defaultValue={project.name}
                  type="text"
                  id="name"
                  sx={inputSx}
                />
                <FormControl fullWidth required margin="normal">
                  <InputLabel id="language-label" sx={labelSx}>
                    {t({ phrase: 'Project status' })}
                  </InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    label={t({ phrase: 'Project status' })}
                    defaultValue={project.status}
                    onChange={(event: SelectChangeEvent) => {
                      project.status = event.target.value as ProjectStatus;
                    }}
                    sx={selectSx}
                  >
                    {projectStatuses.map((status: ProjectStatus) => (
                      <MenuItem key={status} value={status}>
                        {t({ phrase: status })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ ...primaryButtonSx, mt: 3, mb: 2 }}
                  loading={loading}
                  onClick={saveProject}
                >
                  {t({ phrase: 'Save' })}
                </LoadingButton>
                <Link
                  sx={{ textAlign: 'right' }}
                  href={`/projects/details?id=${project.id}`}
                >
                  {t({ phrase: 'Go back' })}
                </Link>
              </>
            </DashboardSectionComponent>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};
