import { UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { isEmail } from 'class-validator';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateProjectDto,
  createProjectValidationSchema,
} from '../../../app/dto/project/create-project.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { convertFileToBase64 } from '../../../app/utils/base64-converter';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { projectsService, usersService } from '../../../bootstrap/di';
import { inputSx, labelSx, primaryButtonSx, selectSx } from '../../../sx';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const CreateProjectPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [createProjectDto, setCreateProjectDto] = useState({
    name: '',
    client: {
      email: '',
      name: '',
      language: Language.pl,
    },
    projectFile: {
      name: '',
      imageBase64: '',
      mime: '',
    },
  });

  const loadClient = async (email: string) => {
    setCreateProjectDto({
      ...createProjectDto,
      client: {
        ...createProjectDto.client,
        email,
      },
    });

    if (!isEmail(email)) {
      return;
    }

    const user = await usersService.getOneByEmail(email);

    setCreateProjectDto({
      ...createProjectDto,
      client: {
        name: user?.name ?? '',
        language: user?.language ?? Language.pl,
        email,
      },
    });
  };

  const storeFile = async (file: any) => {
    setLoading(true);
    const { name, type } = file;
    const imageBase64 = (await convertFileToBase64(file)) as unknown as string;
    setCreateProjectDto({
      ...createProjectDto,
      projectFile: {
        name,
        mime: type,
        imageBase64,
      },
    });
    setLoading(false);
  };

  const createProject = async () => {
    setLoading(true);

    const validationError = Validator.validate<CreateProjectDto>(
      createProjectDto,
      createProjectValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await projectsService.create(createProjectDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);

    navigate(`/projects/details?id=${result.data?.projectId}`, {
      replace: true,
    });
  };

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/projects">
            {t({ phrase: 'Projects' })}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Create project' })}
          </Link>
        </Breadcrumbs>
      </div>

      <Grid container spacing={3} className='zoomable'>
        <Grid item xs={12}>
          <DashboardSectionComponent
            title={t({ phrase: 'Create project' })}
            minHeight={100}
          >
            <>
              <div className="file-input-group">
                <label htmlFor="projectVersionFile">
                  {t({ phrase: 'Select image' })}
                  <UploadFile />
                </label>
                <input
                  id="projectVersionFile"
                  className="file-input"
                  type="file"
                  name="projectVersionFile"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    if (e.target.files?.length) {
                      storeFile(e.target.files[0]);
                    } else {
                      setCreateProjectDto({
                        ...createProjectDto,
                        projectFile: {
                          name: '',
                          imageBase64: '',
                          mime: '',
                        },
                      });
                    }
                  }}
                  multiple={false}
                />
                <p className="file-name">
                  {createProjectDto.projectFile.name
                    ? `${t({ phrase: 'Selected file' })}: ${
                        createProjectDto.projectFile.name
                      }`
                    : ''}
                </p>
              </div>

              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label={t({ phrase: 'Name' })}
                type="text"
                id="name"
                autoFocus
                sx={inputSx}
                onChange={(e) =>
                  setCreateProjectDto({
                    ...createProjectDto,
                    name: e.target.value,
                  })
                }
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t({ phrase: 'Client email address' })}
                name="email"
                autoComplete="email"
                sx={inputSx}
                onChange={(e) => {
                  loadClient(e.target.value);
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="clientName"
                label={t({ phrase: 'Client name' })}
                name="clientName"
                autoComplete="clientName"
                sx={inputSx}
                value={createProjectDto.client.name}
                onChange={(e) =>
                  setCreateProjectDto({
                    ...createProjectDto,
                    client: {
                      ...createProjectDto.client,
                      name: e.target.value,
                    },
                  })
                }
              />

              <FormControl fullWidth required margin="normal">
                <InputLabel id="language-label" sx={labelSx}>
                  {t({ phrase: 'Client language' })}
                </InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  label={t({ phrase: 'Client language' })}
                  value={createProjectDto.client.language}
                  onChange={(e: SelectChangeEvent) =>
                    setCreateProjectDto({
                      ...createProjectDto,
                      client: {
                        ...createProjectDto.client,
                        language: e.target.value as Language,
                      },
                    })
                  }
                  sx={selectSx}
                >
                  {allowedLanguages.map((language: Language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ ...primaryButtonSx, marginTop: '5px', mb: 2 }}
                loading={loading}
                onClick={createProject}
              >
                {t({ phrase: 'Save' })}
              </LoadingButton>
              <Link sx={{ textAlign: 'right' }} href="/projects">
                {t({ phrase: 'Go back' })}
              </Link>
            </>
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
