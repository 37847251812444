import { Breadcrumbs, Button, Grid, Link } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { t } from '../../../app/utils/translator';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import { ProjectListComponent } from '../../components/project/ProjectListComponent';
import { Add } from '@mui/icons-material';
import { borderContainedButtonSx, buttonLinkSx } from '../../../sx';
import { isEmployeeRole } from '../../../app/utils/role-checker';
import { authService } from '../../../bootstrap/di';
import { Role } from '../../../app/enums/role.enum';

export const ProjectsPage = () => {
  const user = authService.getLoggedUser();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
              <Link underline="hover" color="inherit" href="/">
                Aleso
              </Link>
              <Link underline="hover" color="inherit" href="/">
                {t({ phrase: 'Dashboard' })}
              </Link>
              <Link underline="hover" color="text.primary" aria-current="page">
                {t({ phrase: 'Projects' })}
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>

        {isEmployeeRole(user?.role as Role) && (
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }} className='zoomable'>
            <Link href="/projects/create">
              <Button
                className="contained-button"
                sx={{
                  ...buttonLinkSx,
                  ...borderContainedButtonSx,
                }}
              >
                {t({ phrase: 'Add new' })} <Add />
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3} className='zoomable'>
        <Grid item xs={12}>
          <DashboardSectionComponent
            title={t({ phrase: 'Projects' })}
            minHeight={450}
          >
            <Box>
              <ProjectListComponent showControls={true} perPage={5} />
            </Box>
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
