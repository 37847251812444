import React from 'react';
import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { authService } from './bootstrap/di';
import { AuthFormTemplate } from './ui/templates/AuthFormTemplate';
import { LoginPage } from './ui/pages/auth/LoginPage';
import { DashboardPage } from './ui/pages/dashboard/DashboardPage';
import { LogoutPage } from './ui/pages/auth/LogoutPage';
import { RegisterPage } from './ui/pages/auth/RegisterPage';
import { LoggedTemplate } from './ui/templates/logged/LoggedTemplate';
import { AccountPage } from './ui/pages/account/AccountPage';
import { activeRoles, employees, Role } from './app/enums/role.enum';
import { ProjectDetailsPage } from './ui/pages/projects/ProjectDetailsPage';
import { ResponsePage } from './ui/pages/responses/ResponsePage';
import { t } from './app/utils/translator';
import { ProjectsPage } from './ui/pages/projects/ProjectsPage';
import { EditProjectPage } from './ui/pages/projects/EditProjectPage';
import { UploadProjectVersionPage } from './ui/pages/projects/UploadProjectVersionPage';
import { ProjectFilePage } from './ui/pages/projects/project-file/ProjectFilePage';
import { ResetPasswordPage } from './ui/pages/account/ResetPasswordPage';
import { CreateProjectPage } from './ui/pages/projects/CreateProjectPage';
import { UsersPage } from './ui/pages/users/UsersPage';
import { CreateUserPage } from './ui/pages/users/CreateUserPage';
import { EditUserPage } from './ui/pages/users/EditUserPage';
import { ShoutboxPage } from './ui/pages/chat/ShoutboxPage';

export const Routing = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <OnlyGuest>
            <AuthFormTemplate>
              <LoginPage />
            </AuthFormTemplate>
          </OnlyGuest>
        }
      />

      <Route
        path="register"
        element={
          <OnlyGuest>
            <AuthFormTemplate>
              <RegisterPage />
            </AuthFormTemplate>
          </OnlyGuest>
        }
      />

      <Route
        path="reset-password"
        element={
          <OnlyGuest>
            <AuthFormTemplate>
              <ResetPasswordPage />
            </AuthFormTemplate>
          </OnlyGuest>
        }
      />

      <Route
        path="dashboard"
        element={
          <OnlyLogged>
            <LoggedTemplate>
              <DashboardPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="account"
        element={
          <OnlyLogged roles={activeRoles}>
            <LoggedTemplate>
              <AccountPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="users"
        element={
          <OnlyLogged roles={[Role.administrator]}>
            <LoggedTemplate>
              <UsersPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="users/create"
        element={
          <OnlyLogged roles={[Role.administrator]}>
            <LoggedTemplate>
              <CreateUserPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="users/edit"
        element={
          <OnlyLogged roles={[Role.administrator]}>
            <LoggedTemplate>
              <EditUserPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="shoutbox"
        element={
          <OnlyLogged roles={employees}>
            <LoggedTemplate>
              <ShoutboxPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects"
        element={
          <OnlyLogged roles={activeRoles}>
            <LoggedTemplate>
              <ProjectsPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects/create"
        element={
          <OnlyLogged roles={employees}>
            <LoggedTemplate>
              <CreateProjectPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects/details"
        element={
          <OnlyLogged roles={activeRoles}>
            <LoggedTemplate>
              <ProjectDetailsPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects/edit"
        element={
          <OnlyLogged roles={employees}>
            <LoggedTemplate>
              <EditProjectPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects/file"
        element={
          <OnlyLogged roles={activeRoles}>
            <LoggedTemplate>
              <ProjectFilePage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="projects/upload-version"
        element={
          <OnlyLogged roles={employees}>
            <LoggedTemplate>
              <UploadProjectVersionPage />
            </LoggedTemplate>
          </OnlyLogged>
        }
      />

      <Route
        path="logout"
        element={
          <OnlyLogged>
            <LogoutPage />
          </OnlyLogged>
        }
      />

      <Route
        path="*"
        element={
          <ResponsePage
            code={404}
            text={t({ phrase: 'Resource has not been found.' })}
          />
        }
      />

      <Route
        path="404"
        element={
          <ResponsePage
            code={404}
            text={t({ phrase: 'Resource has not been found.' })}
          />
        }
      />
      <Route
        path="403"
        element={
          <ResponsePage
            code={403}
            text={t({
              phrase: 'You are not allowed to perform that operation.',
            })}
          />
        }
      />
    </Routes>
  );
};

function OnlyLogged({
  children,
  roles,
}: {
  children: JSX.Element;
  roles?: Role[];
}) {
  const location = useLocation();

  const loggedUser = authService.getLoggedUser();

  if (!loggedUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (roles?.length && !roles.includes(loggedUser.role)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function OnlyGuest({ children }: { children: JSX.Element }) {
  const location = useLocation();

  const loggedUser = authService.getLoggedUser();

  if (loggedUser) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}
