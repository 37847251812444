import * as Joi from 'joi';

export type ChangeAccountPasswordDto = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

export const changeAccountPasswordValidationSchema = Joi.object({
  oldPassword: Joi.string().min(8),
  password: Joi.string().min(8),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
});
