import { AxiosInstance } from 'axios';
import { GetChatRoomMessagesDto } from '../dto/chat/get-chat-room-messages.dto';
import { TranslatedChatMessageDto } from '../dto/chat/translated-chat-message.dto';
import { Language } from '../enums/language.enum';
import { ChatMessage } from '../models/chat-message';

export class ChatsService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async translateMessage(
    roomSlug: string,
    messageId: string,
    translateTo: Language,
  ): Promise<TranslatedChatMessageDto | null> {
    try {
      const response = await this.apiClient.get<TranslatedChatMessageDto>(
        `chat/rooms/${roomSlug}/messages/${messageId}/translation`,
        {
          params: { translateTo },
        },
      );

      const result = response.data;

      if (!result) {
        return null;
      }

      return result;
    } catch (error) {
      return null;
    }
  }

  async getChatMessages(dto: GetChatRoomMessagesDto): Promise<ChatMessage[]> {
    try {
      const response = await this.apiClient.get<ChatMessage[]>(
        'chat/rooms/messages',
        {
          params: dto,
        },
      );

      const result = response.data;

      if (!result) {
        return [];
      }

      return result;
    } catch (error) {
      return [];
    }
  }
}
