import plTranslations from './pl.json';
import enTranslations from './en.json';
import deTranslations from './de.json';
import { Language } from '../../../app/enums/language.enum';

export const PLTranslationsLibrary = new Map<string, string>();
export const ENTranslationsLibrary = new Map<string, string>();
export const DETranslationsLibrary = new Map<string, string>();

export const getTranslationsByLanguage = (
  language: Language,
): Map<string, string> => {
  switch (language) {
    case Language.pl:
      return PLTranslationsLibrary;
    case Language.de:
      return DETranslationsLibrary;
    default:
      return ENTranslationsLibrary;
  }
};

function mapTranslations(
  translations: Record<string, string>,
  map: Map<string, string>,
) {
  for (const key in translations) {
    map.set(key, translations[key]);
  }
}

mapTranslations(plTranslations, PLTranslationsLibrary);
mapTranslations(enTranslations, ENTranslationsLibrary);
mapTranslations(deTranslations, DETranslationsLibrary);
