import * as Joi from 'joi';

export type SendMessageDto = {
  message: string;
  roomId?: string;
  slug?: string;
};

export const sendMessageValidationSchema = Joi.object({
  message: Joi.string().max(500),
  slug: Joi.string().max(100).optional(),
  roomId: Joi.string().uuid().optional(),
});
