import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { LocalStorageKey } from '../../infrastructure/local-storage/storage/local-storage-key.enum';
import { LocalStorageService } from '../../infrastructure/local-storage/storage/local-storage.service';
import { LoginDto } from '../dto/auth/login.dto';
import { RegisterDto } from '../dto/auth/register.dto';
import { AuthInfo } from '../models/auth-info';
import { Result } from '../models/result';
import { User } from '../models/user';
import { AccountService } from './account.service';

export class AuthService {
  constructor(
    private readonly accountService: AccountService,
    private readonly apiClient: AxiosInstance,
    private readonly localStorageService: LocalStorageService,
  ) {}

  getLoggedUser(): User | null {
    const user = this.localStorageService.get(LocalStorageKey.loggedUser);

    return user ?? null;
  }

  getBearerAuthTokenOrReload(): string {
    const token = this.localStorageService.get(LocalStorageKey.authToken);

    if (!token) {
      window.location.reload();
    }

    return `Bearer ${token}`;
  }

  async register(dto: RegisterDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/auth/register',
        dto,
      );

      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async login(dto: LoginDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post('/auth/login', dto);
      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      this.setAuthInfo(result.data as AuthInfo);

      await this.persistLoggedUser();

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async refreshToken() {
    try {
      const refreshToken = this.localStorageService.get(
        LocalStorageKey.refreshToken,
      );

      if (!refreshToken) {
        this.clearAuthInfo();
        return;
      }

      const response = await this.apiClient.post<BackendResponse<AuthInfo>>(
        '/auth/refresh-token',
        { refreshToken },
      );

      const result = response.data;

      if (!result.success) {
        this.clearAuthInfo();
        return;
      }

      this.setAuthInfo(result.data as AuthInfo);

      await this.persistLoggedUser();
    } catch (error) {}
  }

  async logout() {
    try {
      await this.apiClient.post<BackendResponse<void>>('/auth/logout');

      this.clearAuthInfo();
    } catch (error) {
      window.location.reload();
    }
  }

  async persistLoggedUser() {
    try {
      const user = await this.accountService.getAccount();

      if (!user) {
        return;
      }

      this.localStorageService.persist(LocalStorageKey.loggedUser, user, true);
      this.localStorageService.persist(LocalStorageKey.language, user.language);
    } catch (error) {}
  }

  private setAuthInfo({ token, refreshToken }: AuthInfo) {
    this.localStorageService.persist(LocalStorageKey.authToken, token);
    this.localStorageService.persist(
      LocalStorageKey.refreshToken,
      refreshToken,
    );
  }

  private clearAuthInfo() {
    this.localStorageService.remove(LocalStorageKey.loggedUser);
    this.localStorageService.remove(LocalStorageKey.authToken);
    this.localStorageService.remove(LocalStorageKey.refreshToken);
    window.location.reload();
  }
}
