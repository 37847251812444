import { LocalStorageKey } from './local-storage-key.enum';

export class LocalStorageService {
  persist(key: LocalStorageKey, value: any, strignify = true) {
    if (strignify) {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  }

  get(key: LocalStorageKey, parse = true) {
    const element = localStorage.getItem(key);

    if (!element || element === '' || element === 'undefined') {
      return null;
    }

    return parse ? JSON.parse(element) : element;
  }

  remove(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }
}
