import { Box } from '@mui/system';
import React from 'react';
import { ContrastComponent } from './ContrastComponent';
import { Copyrights } from './Copyrights';

export const Footer = (props: any) => {
  return (
    <Box {...props} className='zoomable'>
      <ContrastComponent />
      <Copyrights />
    </Box>
  );
};
