import * as Joi from 'joi';
import { t } from './translator';

export class Validator {
  static validate<T>(
    data: T,
    schema = Joi.object(),
    options = { allowUnknown: true, presence: 'required' as Joi.PresenceMode },
  ): { error?: string } {
    const status = schema.validate(data, options);

    const error = status.error;

    if (error == null || error.details.length === 0) {
      return {};
    }

    const details = error.details[0];

    return {
      error: t({
        phrase: `:prop0: ${details.type}${
          (details?.context?.limit as string) ?? ''
        }`,
        props: { ':prop0': details.context?.key ?? '' },
        translateProps: true,
      }),
    };
  }
}
