export class Result<T> {
  constructor(
    public readonly success: boolean,
    public readonly data?: T,
    public readonly message?: string,
  ) {}

  static ok<T>(p?: { data?: T; message?: string }): Result<T> {
    return new Result<T>(true, p?.data, p?.message);
  }

  static fail<T>(p?: { data?: T; message?: string }): Result<T> {
    return new Result<T>(false, p?.data, p?.message);
  }
}
