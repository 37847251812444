import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertColor, Stack } from '@mui/material';

export const toastAlertFactory = (
  message: string,
  type: AlertColor,
  fire: boolean,
) => ({ message, type, fire, id: new Date().toISOString() });

export type ToastComponentProps = {
  fire: boolean;
  message: string;
  type: AlertColor;
  id: string;
};

export const ToastComponent = ({
  fire,
  message,
  type,
  id,
}: ToastComponentProps) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(message);
  const [severity, setSeverity] = useState('info');

  useEffect(() => {
    setOpen(fire);
    setContent(message);
    setSeverity(type);
  }, [fire, message, type, id]);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity as AlertColor}
          sx={{ width: '100%' }}
        >
          {content}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
