import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
  CreateUserDto,
  createUserDtoValidationSchema,
} from '../../../app/dto/user/create-user.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { allRoles, Role } from '../../../app/enums/role.enum';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { usersService } from '../../../bootstrap/di';
import { inputSx, labelSx, primaryButtonSx, selectSx } from '../../../sx';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const CreateUserPage = () => {
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [createUserDto, setCreateUserDto] = useState({
    email: '',
    name: '',
    role: Role.client,
    language: Language.pl,
  });

  const createUser = async () => {
    setLoading(true);

    const validationError = Validator.validate<CreateUserDto>(
      createUserDto,
      createUserDtoValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await usersService.create(createUserDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setCreateUserDto({
      email: '',
      name: '',
      role: Role.client,
      language: Language.pl,
    });

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/users">
            {t({ phrase: 'Users' })}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Create user' })}
          </Link>
        </Breadcrumbs>
      </div>

      <Grid container spacing={3} className='zoomable'>
        <Grid item xs={12}>
          <DashboardSectionComponent
            title={t({ phrase: 'Create user' })}
            minHeight={100}
          >
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label={t({ phrase: 'Name' })}
                type="text"
                id="name"
                sx={inputSx}
                autoFocus
                value={createUserDto.name}
                onChange={(e) =>
                  setCreateUserDto({
                    ...createUserDto,
                    name: e.target.value,
                  })
                }
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t({ phrase: 'Email' })}
                name="email"
                autoComplete="email"
                sx={inputSx}
                value={createUserDto.email}
                onChange={(e) =>
                  setCreateUserDto({
                    ...createUserDto,
                    email: e.target.value,
                  })
                }
              />

              <FormControl fullWidth required margin="normal">
                <InputLabel id="language-label" sx={labelSx}>
                  {t({ phrase: 'Language' })}
                </InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  label={t({ phrase: 'language' })}
                  value={createUserDto.language}
                  onChange={(e: SelectChangeEvent) =>
                    setCreateUserDto({
                      ...createUserDto,
                      language: e.target.value as Language,
                    })
                  }
                  sx={selectSx}
                >
                  {allowedLanguages.map((language: Language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required margin="normal">
                <InputLabel id="role-label" sx={labelSx}>
                  {t({ phrase: 'Role' })}
                </InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  label={t({ phrase: 'Role' })}
                  value={createUserDto.role}
                  onChange={(e: SelectChangeEvent) =>
                    setCreateUserDto({
                      ...createUserDto,
                      role: e.target.value as Role,
                    })
                  }
                  sx={selectSx}
                >
                  {allRoles.map((role: Role) => (
                    <MenuItem key={role} value={role}>
                      {t({ phrase: role })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ ...primaryButtonSx, marginTop: '5px', mb: 2 }}
                loading={loading}
                onClick={createUser}
              >
                {t({ phrase: 'Save' })}
              </LoadingButton>
              <Link sx={{ textAlign: 'right' }} href="/users">
                {t({ phrase: 'Go back' })}
              </Link>
            </>
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
