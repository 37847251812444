import * as Joi from 'joi';
import { ProjectStatus } from '../../enums/project-status.enum';

export type UpdateProjectDto = {
  name: string;
  status: ProjectStatus;
};

export const updateProjectValidationSchema = Joi.object({
  name: Joi.string().min(3).max(255),
  status: Joi.string().valid(
    ProjectStatus.open,
    ProjectStatus.inProgress,
    ProjectStatus.finished,
    ProjectStatus.cancelled,
    ProjectStatus.archived,
    ProjectStatus.accepted,
  ),
});
