import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Avatar,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  LoginDto,
  loginDtoValidationSchema,
} from '../../../app/dto/auth/login.dto';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { authService } from '../../../bootstrap/di';
import { bgPrimarySx, inputSx, linkSx, primaryButtonSx } from '../../../sx';
import { LanguageComponent } from '../../components/shared/LanguageComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const LoginPage = () => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.currentTarget);

    const loginDto = {
      email: data.get('email')?.toString(),
      password: data.get('password')?.toString(),
    } as LoginDto;

    const validationError = Validator.validate<LoginDto>(
      loginDto,
      loginDtoValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const loginResult = await authService.login(loginDto);

    if (!loginResult.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: loginResult.message ?? 'Logging in failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setLoading(false);
    window.location.reload();
  };

  return (
    <>
      <Avatar sx={{ m: 1, ...bgPrimarySx }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t({ phrase: 'Sign in' })}
      </Typography>
      <LanguageComponent />
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t({ phrase: 'Email Address' })}
          name="email"
          autoComplete="email"
          sx={inputSx}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t({ phrase: 'Password' })}
          type="password"
          id="password"
          autoComplete="current-password"
          sx={inputSx}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ ...primaryButtonSx, mt: 3, mb: 2 }}
          loading={loading}
        >
          {t({ phrase: 'Sign in' })}
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link href="/reset-password" variant="body2" sx={linkSx}>
              {t({ phrase: 'Forgot password?' })}
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2" sx={linkSx}>
              {t({ phrase: "Don't have an account? Sign Up" })}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
