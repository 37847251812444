import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { GetProjectFilesDto } from '../dto/project/get-project-files.dto';
import { ProjectFile } from '../models/project-file';
import { Result } from '../models/result';

export class ProjectFilesService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async toggleMarked(
    projectId: string,
    projectFileId: string,
  ): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        `/projects/${projectId}/files/${projectFileId}/toggle-marked`,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async delete(
    projectId: string,
    projectFileId: string,
  ): Promise<Result<void>> {
    try {
      const response = await this.apiClient.delete<BackendResponse<void>>(
        `/projects/${projectId}/files/${projectFileId}`,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async getImage(
    projectId: string,
    projectFileId: string,
    onlyData = false,
  ): Promise<HTMLImageElement | null | string> {
    try {
      const response = await this.apiClient.get<string>(
        `/projects/${projectId}/files/${projectFileId}`,
      );

      const result = response.data;

      if (!result) {
        return null;
      }

      if (onlyData) {
        return result;
      }

      const image = new Image();
      image.src = result;

      return image;
    } catch (error) {
      return null;
    }
  }

  async getAll(
    projectId: string,
    dto: GetProjectFilesDto,
  ): Promise<ProjectFile[]> {
    try {
      const response = await this.apiClient.get<ProjectFile[]>(
        `/projects/${projectId}/files`,
        {
          params: dto,
        },
      );

      const result = response.data;

      if (!result) {
        return [];
      }

      return result;
    } catch (error) {
      return [];
    }
  }
}
