import { CookieKey } from './cookie-key.enum';

export class CookiesService {
  setCookie(name: CookieKey, value: string, expiresIn: number) {
    const expirationDate = this.getExpirationDate(expiresIn);

    document.cookie = `${name}=${value};expires=${expirationDate.toUTCString()};path=/"`;
  }

  getCookie(name: CookieKey): string {
    const cookies = decodeURIComponent(document.cookie)
      .split(';')
      .filter((cookie: string) => cookie.includes(name));

    return cookies.length > 0
      ? cookies[0].substring(`${name}=`.length, cookies[0].length)
      : '';
  }

  removeCookie(name: CookieKey) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }

  private getExpirationDate(expiresIn: number): Date {
    const currentDate = new Date();

    currentDate.setTime(currentDate.getTime() + expiresIn);

    return currentDate;
  }
}
