export enum MimeType {
  'jpeg' = 'image/jpeg',
  'jpg' = 'image/jpg',
  'png' = 'image/png',
  'webp' = 'image/webp',
}

export const imageMimeTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];
