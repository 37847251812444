export enum Role {
  administrator = 'administrator',
  employee = 'employee',
  client = 'client',
  inactive = 'inactive',
  blocked = 'blocked',
}

export const activeRoles = [Role.administrator, Role.employee, Role.client];

export const employees = [Role.administrator, Role.employee];

export const allRoles = Object.values(Role);
