import { allowedLanguages, Language } from '../../enums/language.enum';
import * as Joi from 'joi';
import { allRoles, Role } from '../../enums/role.enum';

export type CreateUserDto = {
  email: string;
  name: string;
  language: Language;
  role: Role;
};

export const createUserDtoValidationSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  name: Joi.string().min(3).max(50),
  language: Joi.valid(...allowedLanguages),
  role: Joi.valid(...allRoles),
});
