import { Button } from '@mui/material';
import React from 'react';
import { ProjectStatus } from '../../../app/enums/project-status.enum';
import { t } from '../../../app/utils/translator';

export const ProjectStatusComponent = ({
  status,
}: {
  status: ProjectStatus;
}) => {
  const mapStatusToColor = (status: ProjectStatus) => {
    switch (status) {
      case ProjectStatus.accepted:
        return 'success';
      case ProjectStatus.archived:
        return 'secondary';
      case ProjectStatus.cancelled:
        return 'warning';
      case ProjectStatus.finished:
        return 'success';
      case ProjectStatus.inProgress:
        return 'primary';
      case ProjectStatus.open:
        return 'info';
      default:
        return 'secondary';
    }
  };

  return (
    <Button sx={{ padding: '0px' }} color={mapStatusToColor(status) as any}>
      {t({ phrase: 'Project status' })}: {t({ phrase: status })}
    </Button>
  );
};
