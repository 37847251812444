import { LoadingButton } from '@mui/lab';
import { AlertColor, Button, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../app/models/project';
import { User } from '../../../app/models/user';
import { isAdmin, isEmployeeRole } from '../../../app/utils/role-checker';
import { t } from '../../../app/utils/translator';
import { authService, projectsService } from '../../../bootstrap/di';
import { buttonSx, linkSx } from '../../../sx';
import {
  confirmationFactory,
  ConfirmComponent,
  ConfirmComponentProps,
} from '../shared/ConfirmComponent';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';
import { ProjectStatusComponent } from './ProjectStatusComponent';

export type ProjectInfoComponentProps = {
  project: Project;
};

export const ProjectInfoComponent = ({
  project,
}: ProjectInfoComponentProps) => {
  const user = authService.getLoggedUser() as User;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [confirmation, setConfirmation] = useState(
    null as unknown as ConfirmComponentProps,
  );

  const deleteProject = async (projectId: string) => {
    setLoading(true);

    const result = await projectsService.delete(projectId);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    navigate('/projects');
  };

  return (
    <>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      {confirmation && (
        <ConfirmComponent
          message={confirmation.message}
          callbackFn={confirmation.callbackFn}
          fire={confirmation.fire}
          id={confirmation.id}
          callbackParams={confirmation.callbackParams}
        />
      )}
      {project && (
        <Box>
          <Typography>
            <strong>{t({ phrase: 'Client' })}:</strong> {project.client}
          </Typography>
          <Typography>
            <strong>{project.name}</strong> <small>({project.version})</small>
            {' - '}
            <ProjectStatusComponent status={project.status} />
            <br />
          </Typography>
          <Typography>
            <small>
              {t({
                phrase: 'Created at :date',
                props: { ':date': project.createdAt },
              })}
            </small>
            <br />
            <small>
              {t({
                phrase: 'Updated at :date',
                props: { ':date': project.updatedAt },
              })}
            </small>
          </Typography>
          <br />

          <Box>
            {isEmployeeRole(user.role) && (
              <Link sx={linkSx} href={`/projects/edit?id=${project.id}`}>
                <Button sx={buttonSx} variant="outlined" color="primary">
                  {t({ phrase: 'Edit' })}
                </Button>
              </Link>
            )}{' '}
            {isEmployeeRole(user.role) && (
              <Link
                sx={linkSx}
                href={`/projects/upload-version?id=${project.id}`}
              >
                <Button sx={buttonSx} variant="outlined" color="primary">
                  {t({ phrase: 'Upload version' })}
                </Button>
              </Link>
            )}{' '}
            {isAdmin(user.role) && (
              <LoadingButton
                sx={buttonSx}
                variant="outlined"
                color="error"
                loading={loading}
                onClick={() => {
                  setConfirmation(
                    confirmationFactory(
                      deleteProject,
                      true,
                      t({
                        phrase:
                          'Are you sure you want to continue? This operation cannot be undone.',
                      }),
                      [project.id],
                    ),
                  );
                }}
              >
                {t({ phrase: 'Delete' })}
              </LoadingButton>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
