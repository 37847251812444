import axios, { AxiosRequestConfig } from 'axios';
import { config as c } from '../../bootstrap/config';
import { authService, localStorageService } from '../../bootstrap/di';
import { LocalStorageKey } from '../local-storage/storage/local-storage-key.enum';

export const ApiHttpClient = axios.create({
  baseURL: c.apiUrl,
  timeout: 5000,
});

// add authorization token to API calls
ApiHttpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const authToken = localStorageService.get(LocalStorageKey.authToken);

  if (!authToken || authToken === '') {
    return config;
  }

  if (config.headers) {
    config.headers.authorization = `Bearer ${authToken.replaceAll('"', '')}`;
  }

  return config;
});

// force token refresh if backend responded with outdated bearer token
ApiHttpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error?.response?.data?.status !== 401 &&
      error?.response?.data?.httpCode !== 401
    ) {
      return error?.response;
    }

    const authToken = localStorageService.get(LocalStorageKey.authToken);

    // plain unauthorized exception means that token is incorrect
    if (error?.response?.data?.reasonCode || !authToken) {
      return error?.response;
    }

    await authService.refreshToken();
  },
);
