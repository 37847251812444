import { Breadcrumbs, Grid, Link } from '@mui/material';
import React, { createElement, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Project } from '../../../../app/models/project';
import { ProjectFile } from '../../../../app/models/project-file';
import { t } from '../../../../app/utils/translator';
import { projectFilesService, projectsService } from '../../../../bootstrap/di';
import { DashboardSectionComponent } from '../../../components/shared/DashboardSectionComponent';
import { Loading } from '../../../components/shared/Loading';

export const ProjectFilePage = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState<Project | null>(null);
  const [projectFile, setProjectFile] = useState<ReactElement | null>(null);
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('projectId');
  const projectFileId = searchParams.get('id');

  const load = async () => {
    const loadedProject = await projectsService.getOne(projectId as string);

    if (!loadedProject) {
      navigate('/404', { replace: true });
      return;
    }

    setProject(loadedProject);

    const loadedProjectFile = await projectFilesService.getImage(
      loadedProject.id,
      projectFileId as string,
      true,
    );

    if (!loadedProject) {
      navigate('/404', { replace: true });
      return;
    }

    setProjectFile(
      createElement('img', {
        src: loadedProjectFile,
        className: 'projectFileFullImg',
      }),
    );
  };

  useEffect(() => {
    if (!projectId) {
      navigate('/404', { replace: true });
    }

    load();
  }, []);

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/projects">
            {t({ phrase: 'Projects' })}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/projects/details?id=${project?.id}`}
          >
            {project?.name}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Project image preview' })}
          </Link>
        </Breadcrumbs>
      </div>

      {project && projectFile ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DashboardSectionComponent
              title={t({ phrase: 'Project image preview' })}
              minHeight={100}
            >
              <>
                {projectFile}

                <Link
                  sx={{ paddingTop: '10px' }}
                  href={`/projects/details?id=${project.id}`}
                >
                  {t({ phrase: 'Go back' })}
                </Link>
              </>
            </DashboardSectionComponent>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};
