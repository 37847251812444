import { languageService } from '../../bootstrap/di';
import { getTranslationsByLanguage } from '../../infrastructure/library/translations/translations.library';
import { Language } from '../enums/language.enum';

export type TranslationProps = {
  phrase: string;
  language?: Language;
  props?: Record<string, string> | string[];
  translateProps?: boolean;
};

export type MultipleTranslationProps = {
  phrases: Record<string, string>;
  language?: Language;
  props?: Record<string, string> | string[];
  translateProps?: boolean;
};

export const tAll = ({
  phrases,
  language,
  props,
  translateProps,
}: MultipleTranslationProps): Record<string, string> => {
  const translated: Record<string, string> = {};

  for (const translationKey in phrases) {
    translated[translationKey] = t({
      phrase: phrases[translationKey],
      language,
      props,
      translateProps,
    });
  }

  return translated;
};

export const t = ({
  phrase,
  language,
  props,
  translateProps,
}: TranslationProps): string => {
  if (!language) {
    language = languageService.getLanguage();
  }

  const translations = getTranslationsByLanguage(language as Language);

  let phraseTranslation = translations.get(phrase) ?? phrase;

  if (props) {
    phraseTranslation = replaceProps(
      phraseTranslation,
      props,
      language,
      translateProps,
    );
  }

  return phraseTranslation;
};

const replaceProps = (
  phrase: string,
  props: any,
  language: Language,
  translateProps?: boolean,
): string => {
  for (const propKey in props) {
    const value = props[propKey];

    const propValue = translateProps ? t({ phrase: value, language }) : value;

    phrase = phrase.replaceAll(propKey, propValue);
  }

  return phrase;
};
