import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
  SendMessageDto,
  sendMessageValidationSchema,
} from '../../../app/dto/chat/send-message.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { Role } from '../../../app/enums/role.enum';
import { isEmployeeRole } from '../../../app/utils/role-checker';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { authService } from '../../../bootstrap/di';
import { inputSx, labelSx, primaryButtonSx, selectSx } from '../../../sx';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';
import { shoutboxSlug } from './ChatComponent';

type ChatInputComponentProps = {
  onSendMessage: CallableFunction;
  loading: boolean;
  chatSlug?: string;
};

export const ChatInputComponent = ({
  onSendMessage,
  loading,
  chatSlug,
}: ChatInputComponentProps) => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [message, setMessage] = useState('');
  const [translateTo, setTranslateTo] = useState('');

  const translatable = () =>
    chatSlug !== shoutboxSlug &&
    isEmployeeRole(authService.getLoggedUser()?.role as Role);

  const sendMessage = () => {
    const validationError = Validator.validate<SendMessageDto>(
      { message },
      sendMessageValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      return;
    }

    if (loading) {
      return;
    }

    onSendMessage(message, translateTo || undefined);
    setTranslateTo('');
    setMessage('');
  };

  return (
    <Grid container>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
        <TextField
          id="message"
          label={t({ phrase: 'Your message...' })}
          placeholder={t({ phrase: 'Your message...' })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendMessage();
            }
          }}
          multiline
          fullWidth
          rows={3}
          sx={inputSx}
        />
      </Grid>
      {translatable() && (
        <Grid item xs={4} sx={{ paddingY: '5px', paddingRight: '5px' }}>
          <FormControl fullWidth required>
            <InputLabel id="translate-to-label" sx={labelSx}>
              {t({ phrase: 'Translate to' })}
            </InputLabel>
            <Select
              labelId="translate-to-label"
              id="translate-to"
              label={t({ phrase: 'Translate to' })}
              value={translateTo}
              onChange={(event: SelectChangeEvent) =>
                setTranslateTo(event.target.value as Language)
              }
              sx={{ ...selectSx, height: '45px' }}
            >
              <MenuItem value={'' as Language}>-</MenuItem>
              {allowedLanguages.map((language: Language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={translatable() ? 8 : 12} sx={{ paddingY: '5px' }}>
        <LoadingButton
          loading={loading}
          onClick={sendMessage}
          sx={{ ...primaryButtonSx, height: '45px' }}
          fullWidth
        >
          {t({ phrase: 'Send' })}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
