import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { ChangeAccountPasswordDto } from '../dto/account/change-account-password.dto';
import { OtpDto } from '../dto/otp.dto';
import { UpdateAccountDto } from '../dto/account/update-account.dto';
import { Result } from '../models/result';
import { User } from '../models/user';
import { ResetPasswordDto } from '../dto/account/reset-password.dto';
import { BackendResponseCode } from '../../infrastructure/backend/backend-response-code.enum';
import { t } from '../utils/translator';
import { ApplyNewPasswordDto } from '../dto/account/apply-new-password.dto';

export class AccountService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async getAccount(): Promise<User | null> {
    try {
      const response = await this.apiClient.get<User>('/account');

      const user = response?.data;

      if (!user || !user?.id) {
        return null;
      }

      return user;
    } catch (error) {
      return null;
    }
  }

  async resetPassword(dto: ResetPasswordDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/account/reset-password',
        dto,
      );

      const result = response.data;

      if (
        !result.success &&
        result.reasonCode ===
          BackendResponseCode.accountWithGivenEmailDoesNotExist
      ) {
        return Result.ok();
      }

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async applyNewPassword(dto: ApplyNewPasswordDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/account/apply-new-password',
        dto,
      );

      const result = response.data;

      if (
        !result.success &&
        result.reasonCode ===
          BackendResponseCode.accountWithGivenEmailDoesNotExist
      ) {
        return Result.fail({ message: t({ phrase: 'OTP is incorrect' }) });
      }

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async changePassword(dto: ChangeAccountPasswordDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.patch<BackendResponse<void>>(
        '/account/password',
        dto,
      );

      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async update(dto: UpdateAccountDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.put<BackendResponse<void>>(
        '/account',
        dto,
      );

      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async activate(otpDto: OtpDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/account/activate',
        otpDto,
      );

      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async sendActivationCode(): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/account/send-activation-code',
      );

      const result = response.data;

      if (!result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }
}
