import * as Joi from 'joi';
import { allowedLanguages, Language } from '../../enums/language.enum';
import { imageMimeTypes } from '../../enums/mime-type.enum';

export type CreateProjectDto = {
  name: string;
  client: {
    name: string;
    email: string;
    language: Language;
  };
  projectFile: {
    name: string;
    imageBase64: string;
    mime: string;
  };
};

export const createProjectValidationSchema = Joi.object({
  projectFile: Joi.object({
    imageBase64: Joi.string().min(1).base64(),
    name: Joi.string().min(3).max(255),
    mime: Joi.string().valid(...imageMimeTypes),
  }),
  name: Joi.string().min(3).max(255),
  client: Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }),
    name: Joi.string().min(3).max(50),
    language: Joi.valid(...allowedLanguages),
  }),
});
