import { AccountBox, Email, Flag, Grade } from '@mui/icons-material';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { User } from '../../../app/models/user';
import { t } from '../../../app/utils/translator';

export type AccountInfoComponentProps = {
  user: User;
};

export const AccountInfoComponent = ({ user }: AccountInfoComponentProps) => {
  return (
    <>
      <List sx={{ width: '100%' }}>
        <ListItem disableGutters={true}>
          <ListItemAvatar>
            <Avatar>
              <AccountBox />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={user.name} />
        </ListItem>
        <ListItem disableGutters={true}>
          <ListItemAvatar>
            <Avatar>
              <Email />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={user.email} />
        </ListItem>
        <ListItem disableGutters={true}>
          <ListItemAvatar>
            <Avatar>
              <Grade />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={t({ phrase: user.role })} />
        </ListItem>
        <ListItem disableGutters={true}>
          <ListItemAvatar>
            <Avatar>
              <Flag />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={t({ phrase: user.language })} />
        </ListItem>
      </List>
    </>
  );
};
