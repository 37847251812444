import { Breadcrumbs, Grid, Link } from '@mui/material';
import React from 'react';
import { t } from '../../../app/utils/translator';
import {
  ChatComponent,
  shoutboxSlug,
} from '../../components/chat/ChatComponent';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';

export const ShoutboxPage = () => {
  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Shoutbox' })}
          </Link>
        </Breadcrumbs>
      </div>

      <Grid container spacing={3} className='zoomable'>
        <Grid item xs={12}>
          <DashboardSectionComponent
            title={t({ phrase: 'Shoutbox' })}
            minHeight={100}
          >
            <ChatComponent slug={shoutboxSlug} />
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
