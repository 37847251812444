import { ArrowDownward, ArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { createElement, useEffect, useState } from 'react';
import { GetProjectsDto } from '../../../app/dto/project/get-projects.dto';
import { OrderDirection } from '../../../app/enums/order-direction.enum';
import { ProjectStatus } from '../../../app/enums/project-status.enum';
import { Project } from '../../../app/models/project';
import { User } from '../../../app/models/user';
import { isAdmin, isEmployeeRole } from '../../../app/utils/role-checker';
import { t } from '../../../app/utils/translator';
import { authService, projectsService } from '../../../bootstrap/di';
import { inputSx, linkSx } from '../../../sx';
import { ProjectStatusComponent } from './ProjectStatusComponent';

export type ProjectListComponentProps = {
  showControls: boolean;
  perPage: number;
};

export const ProjectListComponent = ({
  showControls,
  perPage,
}: ProjectListComponentProps) => {
  const user = authService.getLoggedUser() as User;
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    orderBy: 'createdAt',
    orderDirection: OrderDirection.desc,
    client: '',
    name: '',
  });
  const [page, setPage] = useState(1);
  const [pageBlock, setPageBlock] = useState(false);
  const [projects, setProjects] = useState([] as Project[]);

  const handleQueryChange = (q: Partial<GetProjectsDto>) => {
    setPageBlock(false);
    setPage(1);
    setQuery({
      ...query,
      ...q,
    });
  };

  const loadImages = async (projects: Project[]) => {
    const resultWithImages = [];

    for (const project of projects) {
      const thumbnailBase64 = await projectsService.getThumbnailBase64(project.thumbnailUrl);

      resultWithImages.push({
        ...project,
        thumbnail: thumbnailBase64 ? createElement('img', {
          src: thumbnailBase64,
          className: 'projectFileImg',
        }) : null
      });
    }

    return resultWithImages;
  }

  const loadProjects = async (add = false) => {
    setLoading(true);
    const result = await projectsService.getAll({
      orderBy: query.orderBy ?? 'createdAt',
      orderDirection: query.orderDirection ?? OrderDirection.desc,
      client: query.client || undefined,
      name: query.name || undefined,
      page,
      perPage,
    } as unknown as GetProjectsDto);

    if (!result) return;

    if (!result.length && add) {
      setPageBlock(true);
    }

    const resultWithImages = [];

    for (const project of result) {
      const thumbnailBase64 = await projectsService.getThumbnailBase64(project.thumbnailUrl);

      resultWithImages.push({
        ...project,
        thumbnail: thumbnailBase64 ? createElement('img', {
          src: thumbnailBase64,
          className: 'projectFileImg',
        }) : null
      });

      if (add) {
        setProjects([...projects, ...resultWithImages]);
        continue;
      }
      setProjects(resultWithImages);
    }

    setLoading(false);
  };

  useEffect(() => {
    setPageBlock(false);
    setPage(1);
    loadProjects();
  }, [...Object.values(query)]);

  useEffect(() => {
    if (page !== 1) {
      loadProjects(true);
    }
  }, [page]);

  return (
    <>
      {showControls && (
        <Grid
          container
          spacing={3}
          sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: '5px;' }}
        >
          <Grid item xs={12} md={6}>
            <Box>
              {t({ phrase: 'Sort' })}:
              <RadioGroup
                row
                name="orderBy"
                value={query.orderBy}
                onChange={(e) => {
                  handleQueryChange({ orderBy: e.target.value } as any);
                }}
              >
                <FormControlLabel
                  value="status"
                  control={<Radio />}
                  label={t({ phrase: 'By status' })}
                />
                <FormControlLabel
                  value="createdAt"
                  control={<Radio />}
                  label={t({ phrase: 'By createdAt' })}
                />
                <FormControlLabel
                  value="updatedAt"
                  control={<Radio />}
                  label={t({ phrase: 'By updatedAt' })}
                />
                {isEmployeeRole(user.role) && (
                  <FormControlLabel
                    value="clientId"
                    control={<Radio />}
                    label={t({ phrase: 'By client' })}
                  />
                )}
              </RadioGroup>
              <RadioGroup
                row
                name="orderDirection"
                value={query.orderDirection}
                onChange={(e) => {
                  handleQueryChange({ orderDirection: e.target.value } as any);
                }}
              >
                <FormControlLabel
                  value="desc"
                  control={<Radio />}
                  label={t({ phrase: 'asc' })}
                />
                <FormControlLabel
                  value="asc"
                  control={<Radio />}
                  label={t({ phrase: 'desc' })}
                />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              {t({ phrase: 'Search' })}:
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label={t({ phrase: 'By name' })}
                name="name"
                sx={{ ...inputSx, marginBottom: '0px' }}
                defaultValue={query.name}
                onChange={(e) => {
                  if (e.target.value.length > 2 || !e.target.value) {
                    handleQueryChange({ name: e.target.value } as any);
                  }
                }}
              />
              {isEmployeeRole(user.role) && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="client"
                  label={t({ phrase: 'By client' })}
                  name="client"
                  sx={{ ...inputSx, marginTop: '5px' }}
                  defaultValue={query.client}
                  onChange={(e) => {
                    if (e.target.value.length > 2 || !e.target.value) {
                      handleQueryChange({ client: e.target.value } as any);
                    }
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}

      <List sx={{ width: '100%', marginBottom: '10px;' }}>
        {(!projects || !projects.length) && (
          <h2>{t({ phrase: 'No records' })}</h2>
        )}
        {projects?.map((project: Project) => (
          <div key={project.id}>
            <ListItem
              disableGutters={true}
              sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
            >
              <ListItemText
                primary={
                  <>
                    <strong>{project.name}</strong> <small>(ver. {project.version})</small>
                  </>
                }
                secondary={
                  <Typography>
                    {t({ phrase: 'Client' })}: <strong>{project.client}</strong>{' '}
                    <ProjectStatusComponent status={project.status} /> <br />
                    <small>
                      {t({
                        phrase: 'Created at :date',
                        props: { ':date': project.createdAt },
                      })}
                    </small>
                    <br />
                    <small>
                      {t({
                        phrase: 'Updated at :date',
                        props: { ':date': project.updatedAt },
                      })}
                    </small>
                  </Typography>
                }
              />

              {(project.status !== ProjectStatus.archived ||
                isAdmin(user.role)) && (
                  <Link sx={linkSx} href={`/projects/details?id=${project.id}`}>
                    {project.thumbnail as any}
                  </Link>
              )}
            </ListItem>
          </div>
        ))}
      </List>

      {showControls && projects.length > 0 && (
        <Box sx={{ textAlign: 'center' }}>
          {!pageBlock && projects.length && (
            <LoadingButton loading={loading} onClick={() => setPage(page + 1)}>
              {t({ phrase: 'load more' })} <ArrowDownward />
            </LoadingButton>
          )}
        </Box>
      )}
    </>
  );
};
