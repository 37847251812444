import {
  Box,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  ThemeProvider,
} from '@mui/material';
import React from 'react';
import { Copyrights } from '../components/shared/Copyrights';
import { LogoWhiteComponent } from '../components/logo/LogoWhiteComponent';
import { Footer } from '../components/shared/Footer';

type AuthFormTemplateProps = {
  children: JSX.Element;
};

const theme = createTheme();

export const AuthFormTemplate = ({ children }: AuthFormTemplateProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }} className='zoomable'>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url("/hero.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            textAlign: 'center',
            paddingTop: '25px',
            width: '100%',
          }}
        >
          <LogoWhiteComponent />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {children}
            <Footer sx={{ mt: 5, mb: 3 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
