import { allowedLanguages, Language } from '../../enums/language.enum';
import * as Joi from 'joi';

export type UpdateAccountDto = {
  name: string;
  language: Language;
};

export const updateAccountDtoValidationSchema = Joi.object({
  name: Joi.string().min(3).max(50),
  language: Joi.valid(...allowedLanguages),
});
