import { createTheme } from '@mui/material';
import { wcagService } from './bootstrap/di';

export const primaryColor = '#fb4c34';
export const secondaryColor = '#0A0A0A';

export const contrastSx = {
  '.MuiPaper-root': {
    backgroundColor: 'black !important',
    color: 'white',
  },
  '.MuiTypography-root': {
    color: 'white',
  },
  '.MuiLink-root': {
    color: primaryColor,
  },
  '.MuiBreadcrumbs-li > a': {
    color: 'gray',
  },
  '.MuiIconButton-root': {
    color: primaryColor,
  },
  '.MuiListItemIcon-root': {
    color: 'white',
  },
  '.MuiSelect-select': {
    color: 'white',
  },
  '.MuiSelect-iconOutlined': {
    color: primaryColor,
  },
  fieldset: {
    borderColor: primaryColor,
    color: 'white',
  },
  input: {
    background: 'black',
    color: 'white',
  },
  textarea: {
    background: 'black',
    color: 'white',
  },
  '.MuiRadio-colorPrimary': {
    color: primaryColor,
  },
  label: {
    color: 'white',
  },
  select: {
    color: 'white',
  },
  'input:-webkit-autofill, input:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },
  '.contained-button': {
    background: 'black !important',
    color: 'white !important',
  },
  '.MuiButton-textSecondary': {
    color: 'gray',
  },
};

export const appTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

export const linkSx = {
  color: primaryColor,
  textDecoration: 'none',
};

export const labelSx = {
  '&.MuiInputLabel-root': {
    '&.Mui-focused': {
      color: primaryColor,
    },
  },
};

export const selectSx = {
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0px',
    },
    '&:hover fieldset': {
      borderColor: primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryColor,
    },
  },
};

export const inputSx = {
  '& .MuiInputBase-root': {
    borderRadius: '0px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryColor,
    },
  },
  '& label.Mui-focused': {
    color: primaryColor,
  },
};

export const buttonSx = {
  borderRadius: '0px',
};

export const bgPrimarySx = {
  backgroundColor: primaryColor,
  backgroundImage: 'linear-gradient(-218deg,#fb4c34 0%,#e92e64 100%)',
};

export const primaryButtonSx = {
  ...bgPrimarySx,
  padding: '14px 26px',
  color: '#fff',
  textDecoration: 'none',
  transition: 'all .3s',
  borderRadius: '0px',
};

export const buttonLinkSx = {
  ...primaryButtonSx,
  background: 'white !important;',
  color: primaryColor,
};

export const secondaryButtonSx = {
  ...primaryButtonSx,
  background: '#000',
  border: '1px solid #000',
};

export const dialogSx = {
  '& .MuiButton-root': { color: primaryColor },
  '& .MuiDialog-paper': {
    borderRadius: '0px',
    border: 'none',
    background: wcagService.isContrastMode() ? 'black' : 'white',
    color: wcagService.isContrastMode() ? 'white' : 'inherit',
  },
  '& .MuiDialogContentText-root': {
    color: wcagService.isContrastMode() ? 'white' : 'inherit',
  },
};

export const borderContainedButtonSx = {
  boxShadow: '0px -2px 1px 1px rgb(0 0 0 / 12%)',
};
