import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Avatar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  RegisterDto,
  registerDtoValidationSchema,
} from '../../../app/dto/auth/register.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { authService } from '../../../bootstrap/di';
import {
  bgPrimarySx,
  inputSx,
  labelSx,
  linkSx,
  primaryButtonSx,
  selectSx,
} from '../../../sx';
import { LanguageComponent } from '../../components/shared/LanguageComponent';
import {
  toastAlertFactory,
  ToastComponent,
} from '../../components/shared/ToastComponent';

export const RegisterPage = () => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [selectedLang, setSelectedLang] = useState(Language.pl);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.currentTarget);

    const registerDto = {
      email: data.get('email')?.toString(),
      password: data.get('password')?.toString(),
      passwordConfirmation: data.get('passwordConfirmation')?.toString(),
      name: data.get('name')?.toString(),
      language: selectedLang,
    } as RegisterDto;

    const validationError = Validator.validate<RegisterDto>(
      registerDto,
      registerDtoValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await authService.register(registerDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Registration failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(
      toastAlertFactory(
        t({ phrase: 'Your account has been created. You can now log in.' }),
        'success',
        true,
      ),
    );

    setAccountCreated(true);

    setLoading(false);
  };

  return (
    <>
      <Avatar sx={{ m: 1, ...bgPrimarySx }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t({ phrase: 'Sign up' })}
      </Typography>
      <LanguageComponent />
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      {accountCreated && (
        <>
          <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
            <br />
            {t({ phrase: 'Your account has been created.' })}
          </Typography>
          <Link href="/" variant="body2" sx={linkSx}>
            <h3>{t({ phrase: 'Go to sign in page to log in' })}</h3>
          </Link>
        </>
      )}
      {!accountCreated && (
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t({ phrase: 'Email Address' })}
            name="email"
            autoComplete="email"
            autoFocus
            sx={inputSx}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t({ phrase: 'Your name' })}
            name="name"
            autoComplete="name"
            sx={inputSx}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t({ phrase: 'Password' })}
            type="password"
            id="password"
            autoComplete="current-password"
            sx={inputSx}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label={t({ phrase: 'Password confirmation' })}
            type="password"
            id="passwordConfirmation"
            sx={inputSx}
          />
          <FormControl fullWidth required margin="normal">
            <InputLabel id="language-label" sx={labelSx}>
              {t({ phrase: 'Language' })}
            </InputLabel>
            <Select
              labelId="language-label"
              id="language"
              label={t({ phrase: 'Language' })}
              value={selectedLang}
              onChange={(event: SelectChangeEvent) =>
                setSelectedLang(event.target.value as Language)
              }
              sx={selectSx}
            >
              {allowedLanguages.map((language: Language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ...primaryButtonSx, mt: 3, mb: 2 }}
            loading={loading}
          >
            {t({ phrase: 'Sign up' })}
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2" sx={linkSx}>
                {t({ phrase: 'Forgot password?' })}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/" variant="body2" sx={linkSx}>
                {t({ phrase: 'Do you have account? Log in' })}
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
