export enum ProjectStatus {
  open = 'open',
  inProgress = 'inProgress',
  accepted = 'accepted',
  finished = 'finished',
  cancelled = 'cancelled',
  archived = 'archived',
}

export const projectStatuses = Object.values(ProjectStatus);
