import { Breadcrumbs, Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Project } from '../../../app/models/project';
import { t } from '../../../app/utils/translator';
import { projectsService } from '../../../bootstrap/di';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import { Loading } from '../../components/shared/Loading';
import { ProjectFilesComponent } from '../../components/project/project-files/ProjectFilesComponent';
import { ProjectInfoComponent } from '../../components/project/ProjectInfoComponent';
import { ChatComponent } from '../../components/chat/ChatComponent';

export const ProjectDetailsPage = () => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('id');

  const loadProject = async () => {
    if (!projectId) {
      navigate('/404', { replace: true });
    }

    const loaded = await projectsService.getOne(projectId as string);

    if (!loaded) {
      navigate('/404', { replace: true });
      return;
    }

    setProject(loaded);
  };

  useEffect(() => {
    loadProject();
  }, []);

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="inherit" href="/projects">
            {t({ phrase: 'Projects' })}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {project?.name}
          </Link>
        </Breadcrumbs>
      </div>
      <Grid container spacing={3}>
        {!editMode && (
          <Grid item xs={12} className='zoomable'>
            <DashboardSectionComponent
              title={t({ phrase: 'Project info' })}
              minHeight={100}
            >
              {project ? (
                <ProjectInfoComponent project={project as Project} />
              ) : (
                <Loading />
              )}
            </DashboardSectionComponent>
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <DashboardSectionComponent
            minHeight={550}
            title={
              editMode
                ? t({ phrase: 'Edit project' })
                : t({ phrase: 'Project files' })
            }
          >
            {project ? (
              <ProjectFilesComponent
                project={project as Project}
                onEditModeChange={setEditMode}
              />
            ) : (
              <Loading />
            )}
          </DashboardSectionComponent>
        </Grid>
        <Grid item xs={12} md={4} className='zoomable'>
          <DashboardSectionComponent
            title={t({ phrase: 'Chat' })}
            minHeight={550}
          >
            <ChatComponent slug={projectId as string} />
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
