import { LoadingButton } from '@mui/lab';
import { AlertColor, Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import {
  ChangeAccountPasswordDto,
  changeAccountPasswordValidationSchema,
} from '../../../app/dto/account/change-account-password.dto';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { accountService, authService } from '../../../bootstrap/di';
import { inputSx, primaryButtonSx } from '../../../sx';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';

export const ChangeAccountPasswordComponent = () => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.currentTarget;

    setLoading(true);

    const data = new FormData(target);

    const changeAccountPasswordDto = {
      oldPassword: data.get('oldPassword')?.toString(),
      password: data.get('password')?.toString(),
      passwordConfirmation: data.get('passwordConfirmation')?.toString(),
    } as ChangeAccountPasswordDto;

    const validationError = Validator.validate<ChangeAccountPasswordDto>(
      changeAccountPasswordDto,
      changeAccountPasswordValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await accountService.changePassword(
      changeAccountPasswordDto,
    );

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    await authService.persistLoggedUser();

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  return (
    <div>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      {t({
        phrase:
          'You can use below form to change your password. Remember to put your old password and put new one twice.',
      })}
      <Box
        component="form"
        noValidate
        onSubmit={(e) => {
          handleSubmit(e);
          e.currentTarget.reset();
        }}
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="oldPassword"
          label={t({ phrase: 'Current password' })}
          type="password"
          id="oldPassword"
          autoComplete="current-password"
          sx={inputSx}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t({ phrase: 'Password' })}
          type="password"
          id="password"
          autoComplete="password"
          sx={inputSx}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label={t({ phrase: 'Password confirmation' })}
          type="password"
          id="passwordConfirmation"
          sx={inputSx}
        />

        <LoadingButton
          type="submit"
          variant="outlined"
          sx={{ ...primaryButtonSx, mt: 3 }}
          loading={loading}
        >
          {t({ phrase: 'Save' })}
        </LoadingButton>
      </Box>
    </div>
  );
};
