import { Button } from '@mui/material';
import React from 'react';
import { Role } from '../../../app/enums/role.enum';
import { t } from '../../../app/utils/translator';

export const UserRoleComponent = ({ role }: { role: Role }) => {
  const mapRoleToColor = (role: Role) => {
    switch (role) {
      case Role.administrator:
        return 'success';
      case Role.employee:
        return 'primary';
      case Role.client:
        return 'warning';
      default:
        return 'secondary';
    }
  };

  return (
    <Button sx={{ padding: '0px' }} color={mapRoleToColor(role) as any}>
      {t({ phrase: 'Role' })}: {t({ phrase: role })}
    </Button>
  );
};
