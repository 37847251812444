import * as Joi from 'joi';

export type LoginDto = {
  email: string;
  password: string;
};

export const loginDtoValidationSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string(),
});
