import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import { t } from '../../../app/utils/translator';
import { wcagService } from '../../../bootstrap/di';
import { primaryButtonSx, primaryColor, secondaryColor } from '../../../sx';

export type ResponsePageProps = {
  code: number;
  text?: string;
};

export const ResponsePage = ({ code, text }: ResponsePageProps) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        paddingTop: '50px',
        height: '100vh',
        background: wcagService.isContrastMode() ? 'black' : 'white',
      }}
    >
      <Typography
        sx={{
          color: primaryColor,
        }}
        variant="h1"
      >
        {code}
      </Typography>
      <Typography
        sx={{
          color: secondaryColor,
        }}
        variant="h5"
      >
        {text}
      </Typography>
      <br />
      <Link href="/">
        <Button sx={primaryButtonSx}>
          {t({ phrase: 'Back to the main page' })}
        </Button>
      </Link>
    </Box>
  );
};
