import { allowedLanguages, Language } from '../../enums/language.enum';
import * as Joi from 'joi';

export type RegisterDto = {
  email: string;
  password: string;
  passwordConfirmation: string;
  name: string;
  language: Language;
};

export const registerDtoValidationSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string().min(8),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
  name: Joi.string().min(3).max(50),
  language: Joi.valid(...allowedLanguages),
});
