import { LoadingButton } from '@mui/lab';
import { AlertColor, Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { OtpDto, otpValidationSchema } from '../../../app/dto/otp.dto';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { accountService, authService } from '../../../bootstrap/di';
import { inputSx, primaryButtonSx } from '../../../sx';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';

export const ActivateAccountComponent = () => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);

  const handleReSend = async () => {
    setLoading(true);

    const result = await accountService.sendActivationCode();

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setToast(
      toastAlertFactory(
        t({
          phrase:
            result.message ??
            'New activation code has been sent. Check your email.',
        }),
        'success',
        true,
      ),
    );

    setLoading(false);
  };

  const handleActivate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.currentTarget);
    const otpDto = { otp: data.get('otp')?.toString() as string };
    const validationError = Validator.validate<OtpDto>(
      otpDto,
      otpValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await accountService.activate(otpDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Account activation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setLoading(false);

    await authService.persistLoggedUser();

    window.location.reload();
  };

  return (
    <div>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      {t({
        phrase:
          "Your account is not active. After registration we sent you an access code. Fill below field with it's value to activate the account.",
      })}
      <Box component="form" noValidate onSubmit={handleActivate} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="otp"
          label={t({ phrase: 'otp' })}
          name="otp"
          sx={inputSx}
        />

        <LoadingButton
          type="submit"
          variant="outlined"
          sx={{ ...primaryButtonSx, mt: 3 }}
          loading={loading}
        >
          {t({ phrase: 'Activate' })}
        </LoadingButton>
        <br />
        <LoadingButton
          type="button"
          variant="text"
          sx={{ mt: 2 }}
          loading={loading}
          onClick={handleReSend}
        >
          {t({ phrase: 'Send me new activation code' })}
        </LoadingButton>
      </Box>
    </div>
  );
};
