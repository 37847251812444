import * as Joi from 'joi';
import { OtpDto, otpValidationSchema } from '../otp.dto';

export type ApplyNewPasswordDto = {
  email: string;
  password: string;
  passwordConfirmation: string;
} & OtpDto;

export const applyNewPasswordValidationSchema = otpValidationSchema.append({
  email: Joi.string().email({ tlds: { allow: false } }),
  otp: Joi.string().length(4).regex(/^\d+$/),
  password: Joi.string().min(8),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
});
