import { LocalStorageKey } from '../../infrastructure/local-storage/storage/local-storage-key.enum';
import { LocalStorageService } from '../../infrastructure/local-storage/storage/local-storage.service';
import { Language } from '../enums/language.enum';

export class LanguageService {
  constructor(private readonly localStorageService: LocalStorageService) {}

  setLanguage(language: Language) {
    this.localStorageService.persist(LocalStorageKey.language, language);
  }

  getLanguage(): Language {
    return (
      this.localStorageService.get(LocalStorageKey.language) || Language.pl
    );
  }
}
