import { Box, ThemeProvider } from '@mui/material';
import { Routing } from './Routing';
import React from 'react';
import { appTheme, contrastSx } from './sx';
import { wcagService } from './bootstrap/di';
import { zoomCss } from './zoom-css';

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      {wcagService.isFontSizeIncrease() && <style>{zoomCss}</style>}
      <Box sx={wcagService.isContrastMode() ? contrastSx : {}}>
        <Routing />
      </Box>
    </ThemeProvider>
  );
}

export default App;
