import { LoadingButton } from '@mui/lab';
import {
  AlertColor,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
  UpdateAccountDto,
  updateAccountDtoValidationSchema,
} from '../../../app/dto/account/update-account.dto';
import { allowedLanguages, Language } from '../../../app/enums/language.enum';
import { User } from '../../../app/models/user';
import { t } from '../../../app/utils/translator';
import { Validator } from '../../../app/utils/validator';
import { accountService, authService } from '../../../bootstrap/di';
import { inputSx, labelSx, primaryButtonSx, selectSx } from '../../../sx';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';

export type EditAccountInfoComponentProps = {
  user: User;
};

export const EditAccountInfoComponent = ({
  user,
}: EditAccountInfoComponentProps) => {
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [loading, setLoading] = useState(false);
  const [selectedLang, setSelectedLang] = useState(user.language);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.currentTarget);

    const updateAccountDto = {
      name: data.get('name')?.toString(),
      language: selectedLang,
    } as UpdateAccountDto;

    const validationError = Validator.validate<UpdateAccountDto>(
      updateAccountDto,
      updateAccountDtoValidationSchema,
    );

    if (validationError.error) {
      setToast(toastAlertFactory(validationError.error, 'error', true));
      setLoading(false);
      return;
    }

    const result = await accountService.update(updateAccountDto);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    await authService.persistLoggedUser();

    window.location.reload();
  };

  return (
    <div>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      {t({
        phrase:
          "You can use below form to change your name or application's language.",
      })}
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label={t({ phrase: 'Your name' })}
          name="name"
          sx={inputSx}
          defaultValue={user.name}
        />

        <FormControl fullWidth required margin="normal">
          <InputLabel id="language-label" sx={labelSx}>
            {t({ phrase: 'Language' })}
          </InputLabel>
          <Select
            labelId="language-label"
            id="language"
            label={t({ phrase: 'Language' })}
            value={selectedLang}
            onChange={(event: SelectChangeEvent) =>
              setSelectedLang(event.target.value as Language)
            }
            sx={selectSx}
          >
            {allowedLanguages.map((language: Language) => (
              <MenuItem
                key={language}
                value={language}
                selected={selectedLang === language}
              >
                {language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          type="submit"
          variant="outlined"
          sx={{ ...primaryButtonSx, mt: 3 }}
          loading={loading}
        >
          {t({ phrase: 'Save' })}
        </LoadingButton>
      </Box>
    </div>
  );
};
