import { ArrowDownward, ArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { GetUsersDto } from '../../../app/dto/user/get-users.dto';
import { OrderDirection } from '../../../app/enums/order-direction.enum';
import { User } from '../../../app/models/user';
import { t } from '../../../app/utils/translator';
import { usersService } from '../../../bootstrap/di';
import { inputSx, linkSx } from '../../../sx';
import { UserRoleComponent } from './UserRoleComponent';

export type UsersListComponentProps = {
  showControls: boolean;
  perPage: number;
};

export const UsersListComponent = ({
  showControls,
  perPage,
}: UsersListComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    orderBy: 'createdAt',
    orderDirection: OrderDirection.desc,
    role: '',
    language: '',
    search: '',
  });
  const [page, setPage] = useState(1);
  const [pageBlock, setPageBlock] = useState(false);
  const [users, setUsers] = useState([] as User[]);

  const handleQueryChange = (q: Partial<GetUsersDto>) => {
    setPageBlock(false);
    setPage(1);
    setQuery({
      ...query,
      ...q,
    });
  };

  const loadUsers = async (add = false) => {
    setLoading(true);
    const result = await usersService.getAll({
      orderBy: query.orderBy ?? 'createdAt',
      orderDirection: query.orderDirection ?? OrderDirection.desc,
      search: query.search || undefined,
      role: query.role || undefined,
      language: query.language || undefined,
      page,
      perPage,
    } as unknown as GetUsersDto);

    if (!result) return;

    if (!result.length && add) {
      setPageBlock(true);
    }

    if (add) {
      setUsers([...users, ...result]);
      setLoading(false);
      return;
    }

    setUsers(result);
    setLoading(false);
  };

  useEffect(() => {
    setPageBlock(false);
    setPage(1);
    loadUsers();
  }, [...Object.values(query)]);

  useEffect(() => {
    if (page !== 1) {
      loadUsers(true);
    }
  }, [page]);

  return (
    <>
      {showControls && (
        <Grid
          container
          spacing={3}
          sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: '5px;' }}
        >
          <Grid item xs={12} md={6}>
            <Box>
              {t({ phrase: 'Sort' })}:
              <RadioGroup
                row
                name="orderBy"
                value={query.orderBy}
                onChange={(e) => {
                  handleQueryChange({ orderBy: e.target.value } as any);
                }}
              >
                <FormControlLabel
                  value="name"
                  control={<Radio />}
                  label={t({ phrase: 'By name' })}
                />
                <FormControlLabel
                  value="name"
                  control={<Radio />}
                  label={t({ phrase: 'By email' })}
                />
                <FormControlLabel
                  value="role"
                  control={<Radio />}
                  label={t({ phrase: 'By role' })}
                />
                <FormControlLabel
                  value="language"
                  control={<Radio />}
                  label={t({ phrase: 'By language' })}
                />
                <FormControlLabel
                  value="createdAt"
                  control={<Radio />}
                  label={t({ phrase: 'By createdAt' })}
                />
              </RadioGroup>
              <RadioGroup
                row
                name="orderDirection"
                value={query.orderDirection}
                onChange={(e) => {
                  handleQueryChange({ orderDirection: e.target.value } as any);
                }}
              >
                <FormControlLabel
                  value="desc"
                  control={<Radio />}
                  label={t({ phrase: 'asc' })}
                />
                <FormControlLabel
                  value="asc"
                  control={<Radio />}
                  label={t({ phrase: 'desc' })}
                />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              {t({ phrase: 'Search' })}:
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label={t({ phrase: 'By name or email address' })}
                name="name"
                sx={{ ...inputSx, marginBottom: '0px' }}
                defaultValue={query.search}
                onChange={(e) => {
                  if (e.target.value.length > 2 || !e.target.value) {
                    handleQueryChange({ search: e.target.value } as any);
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <List sx={{ width: '100%', marginBottom: '10px;' }}>
        {(!users || !users.length) && <h2>{t({ phrase: 'No records' })}</h2>}
        {users?.map((user: User) => (
          <div key={user.id}>
            <ListItem
              disableGutters={true}
              sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
            >
              <ListItemText
                primary={
                  <>
                    {user.name} <small>({user.language})</small>
                  </>
                }
                secondary={
                  <Typography>
                    {user.email}
                    <br />
                    <UserRoleComponent role={user.role} /> <br />
                  </Typography>
                }
              />

              <IconButton color="primary">
                <Link sx={linkSx} href={`/users/edit?id=${user.id}`}>
                  {t({ phrase: 'Edit' })} <ArrowRight />
                </Link>
              </IconButton>
            </ListItem>
          </div>
        ))}
      </List>

      {showControls && users.length > 0 && (
        <Box sx={{ textAlign: 'center' }}>
          {!pageBlock && users.length && (
            <LoadingButton loading={loading} onClick={() => setPage(page + 1)}>
              {t({ phrase: 'load more' })} <ArrowDownward />
            </LoadingButton>
          )}
        </Box>
      )}
    </>
  );
};
