import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { buttonSx, dialogSx } from '../../../sx';
import { t } from '../../../app/utils/translator';

export const confirmationFactory = (
  callbackFn: CallableFunction,
  fire: boolean,
  message: string,
  callbackParams?: any[],
) => ({
  callbackFn,
  fire,
  message,
  id: new Date().toISOString(),
  callbackParams,
});

export type ConfirmComponentProps = {
  callbackFn: CallableFunction;
  fire: boolean;
  id: string;
  message?: string;
  callbackParams?: any[];
};

export const ConfirmComponent = ({
  callbackFn,
  fire,
  id,
  message,
  callbackParams,
}: ConfirmComponentProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(fire);
  }, [fire, message, id]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="confirm">
      <Dialog
        sx={dialogSx}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm__dialog"
      >
        <DialogTitle id="alert-dialog-title">
          {t({ phrase: 'Confirm operation' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleClose}
            sx={buttonSx}
          >
            {t({ phrase: 'No' })}
          </Button>
          <Button
            sx={buttonSx}
            variant="outlined"
            color="primary"
            onClick={() => {
              handleClose();
              callbackParams ? callbackFn(...callbackParams) : callbackFn();
            }}
            autoFocus
          >
            {t({ phrase: 'Yes' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
