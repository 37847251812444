import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { CreateUserDto } from '../dto/user/create-user.dto';
import { GetUsersDto } from '../dto/user/get-users.dto';
import { UpdateUserDto } from '../dto/user/update-user.dto';
import { Result } from '../models/result';
import { User } from '../models/user';

export class UsersService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async create(dto: CreateUserDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        '/users',
        dto,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async generateNewPassword(userId: string): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<
      BackendResponse<{ id: string }>
      >(`/users/${userId}/generate-password`);

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async update(userId: string, dto: UpdateUserDto): Promise<Result<void>> {
    try {
      const response = await this.apiClient.put<BackendResponse<void>>(
        `/users/${userId}`,
        dto,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async delete(userId: string): Promise<Result<void>> {
    try {
      const response = await this.apiClient.delete<BackendResponse<void>>(
        `/users/${userId}`,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async getOne(userId: string): Promise<User | null> {
    try {
      const response = await this.apiClient.get<User>(`/users/${userId}`);

      const result = response.data;

      if (!result || response.status !== 200) {
        return null;
      }

      return result;
    } catch (error) {
      return null;
    }
  }

  async getAll(dto: GetUsersDto): Promise<User[]> {
    try {
      const response = await this.apiClient.get<User[]>('/users', {
        params: dto,
      });

      const result = response.data;

      if (!result) {
        return [];
      }

      return result;
    } catch (error) {
      return [];
    }
  }

  async getOneByEmail(email: string): Promise<User | null> {
    try {
      const response = await this.apiClient.get<User>('/users/one-by-email', {
        params: { email },
      });

      const result = response.data;

      if (!result) {
        return null;
      }

      return result;
    } catch (error) {
      return null;
    }
  }
}
