import { AxiosInstance } from 'axios';
import { BackendResponse } from '../../infrastructure/backend/backend-response';
import { CreateProjectDto } from '../dto/project/create-project.dto';
import { GetProjectsDto } from '../dto/project/get-projects.dto';
import { UpdateProjectDto } from '../dto/project/update-project.dto';
import { UploadProjectVersionDto } from '../dto/project/upload-project-version.dto';
import { Project } from '../models/project';
import { Result } from '../models/result';

export class ProjectsService {
  constructor(private readonly apiClient: AxiosInstance) {}

  async getThumbnailBase64(
    thumbnailUrl: string
  ): Promise<string | null> {
    try {
      const response = await this.apiClient.get<string>(thumbnailUrl);

      const result = response.data;

      if (!result) {
        return null;
      }

      return result;
    } catch (error) {
      return null;
    }
  }

  async create(dto: CreateProjectDto): Promise<Result<{ projectId?: string }>> {
    try {
      const response = await this.apiClient.post<
      BackendResponse<{ id: string }>
      >('/projects', dto);

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok({ data: { projectId: result?.data?.id } });
    } catch (error) {
      return Result.fail();
    }
  }

  async update(
    projectId: string,
    dto: UpdateProjectDto,
  ): Promise<Result<void>> {
    try {
      const response = await this.apiClient.put<BackendResponse<void>>(
        `/projects/${projectId}`,
        dto,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async delete(projectId: string): Promise<Result<void>> {
    try {
      const response = await this.apiClient.delete<BackendResponse<void>>(
        `/projects/${projectId}`,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async uploadVersion(
    projectId: string,
    dto: UploadProjectVersionDto,
  ): Promise<Result<void>> {
    try {
      const response = await this.apiClient.post<BackendResponse<void>>(
        `/projects/${projectId}/version`,
        dto,
      );

      const result = response.data;

      if (!result || !result.success) {
        return Result.fail({ message: result.message });
      }

      return Result.ok();
    } catch (error) {
      return Result.fail();
    }
  }

  async getOne(projectId: string): Promise<Project | null> {
    try {
      const response = await this.apiClient.get<Project>(
        `/projects/${projectId}`,
      );

      const result = response.data;

      if (!result || response.status !== 200) {
        return null;
      }

      return result;
    } catch (error) {
      return null;
    }
  }

  async getAll(dto: GetProjectsDto): Promise<Project[]> {
    try {
      const response = await this.apiClient.get<Project[]>('/projects', {
        params: dto,
      });

      const result = response.data;

      if (!result) {
        return [];
      }

      return result;
    } catch (error) {
      return [];
    }
  }
}
