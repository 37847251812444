import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';
import { t, tAll } from '../../../app/utils/translator';
import {
  languageService,
  localStorageService,
  wcagService,
} from '../../../bootstrap/di';
import { LocalStorageKey } from '../../../infrastructure/local-storage/storage/local-storage-key.enum';
import { imgEditContrastCss } from './contrast-css';
import { imageEditorTranslationKeys } from './image-editor-translation-keys';
import './ImageEditorComponent.css';
import { PanTool } from '@mui/icons-material';
import { renderToString } from 'react-dom/server'

export type ImageEditorConfig = {
  source: null | HTMLImageElement;
  onSaveFunction: CallableFunction;
  opened?: boolean;
};

export type ImageEditorComponentProps = {
  config: ImageEditorConfig;
};

export const ImageEditorComponent = ({ config }: ImageEditorComponentProps) => {
  const [contrast, setContrast] = useState(false);
  const [zoom, setZoom] = useState(false);
  const { source, opened, onSaveFunction } = config;
  const [isImgEditorShown, setIsImgEditorShown] = useState(!!opened);
  const [imgToShow, setImgToShow] = useState(source);
  const defaultColors = [
    '#ff0000',
    '#3cff00',
    '#0019ff',
    '#ffcc00',
    '#000000',
    '#ff00f7',
    '#ff5100',
    '#a600ff',
    '#338500',
  ];

  useEffect(() => {
    setIsImgEditorShown(true);
    setImgToShow(source);
  }, [source, opened]);

  useEffect(() => {
    const pinnedColors =
      localStorageService.get(LocalStorageKey.imageEditorDefaultColors) || [];

    const colorsToSet = defaultColors;
    pinnedColors.forEach((color: string) => {
      if (!colorsToSet.includes(color)) {
        colorsToSet.push(color);
      }
    });

    localStorageService.persist(
      LocalStorageKey.imageEditorDefaultColors,
      colorsToSet,
      true,
    );
  });

  useEffect(() => {
    setContrast(wcagService.isContrastMode());
    setZoom(wcagService.isFontSizeIncrease());
  });

  // useEffect(() => {
  //   if (zoom) {
  //     const editorRoot = document.getElementsByClassName('FIE_root')[0] as any;

  //     if (editorRoot) {
  //       editorRoot.style = 'zoom: 87%'
  //     }
  //   }
  // }, [zoom])

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key !== 'Delete' && event.key !== 'Backspace') {
        return;
      }

      const controls = document.getElementsByClassName(
        'FIE_annotation-controls-overlay',
      )[0];

      if (!controls) {
        return;
      }

      const deleteButton = controls.getElementsByTagName('Button')[1] as any;

      if (!deleteButton) {
        return;
      }

      deleteButton.click();
    });
  });

  useEffect(() => {
    setTimeout(() => {
      const tabs = document.getElementsByClassName('FIE_tabs')[0];

      if (!tabs) {
        return;
      }

      const moveElement = tabs.getElementsByClassName('FIE_tab')[0];

      if (!moveElement) {
        return;
      }

      moveElement.innerHTML = renderToString(<><PanTool /><label className='FIE_tab-label SfxLabel-root'>{t({ phrase: 'Resize' })}</label></>)
    }, 500)
  }, [isImgEditorShown, imgToShow])

  const closeImgEditor = () => {
    window.location.reload();
  };

  return (
    <Box sx={{ paddingTop: '35px' }} className='imgEditorBox'>
      {contrast && <style>{imgEditContrastCss}</style>}
      {isImgEditorShown && imgToShow && (
        <FilerobotImageEditor
          useBackendTranslations={true}
          translations={tAll({
            phrases: imageEditorTranslationKeys,
            language: languageService.getLanguage(),
          })}
          savingPixelRatio={9}
          previewPixelRatio={9}
          source={imgToShow}
          onSave={(editedImageObject, designState) => {
            onSaveFunction(editedImageObject);
          }}
          onClose={closeImgEditor}
          Text={{ text: 'Aleso...', fontSize: 16 }}
          tabsIds={[TABS.RESIZE, TABS.ANNOTATE]}
          defaultTabId={TABS.RESIZE}
          defaultToolId={TOOLS.ARROW}
          Line={{ strokeWidth: 4 }}
          Pen={{ strokeWidth: 4 }}
        />
      )}
    </Box>
  );
};
