import { AlertColor, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatSocketEvent } from '../../../app/enums/chat-socket-event.enum';
import { ChatMessage } from '../../../app/models/chat-message';
import {
  authService,
  chatsService,
  languageService,
  shoutboxService,
} from '../../../bootstrap/di';
import { ChatInputComponent } from './ChatInputComponent';
import { ChatMessagesComponent } from './ChatMessagesComponent';
import { io } from 'socket.io-client';
import { config } from '../../../bootstrap/config';
import { toastAlertFactory, ToastComponent } from '../shared/ToastComponent';
import { t } from '../../../app/utils/translator';
import { Language } from '../../../app/enums/language.enum';

type ChatComponentProps = {
  slug?: string;
  id?: string;
};

export const shoutboxSlug = 'shoutbox';

let socket: any = null;

if (authService.getLoggedUser()) {
  socket = io(config.socketUri, {
    auth: {
      token: authService.getBearerAuthTokenOrReload(),
    },
  });
}

export const ChatComponent = ({ slug, id }: ChatComponentProps) => {
  const currentLang = languageService.getLanguage();
  const [page, setPage] = useState(1);
  const [pageBlock, setPageBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([] as ChatMessage[]);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const navigate = useNavigate();

  const scrollMessages = () => {
    const objDiv = document.getElementById('chatMessages');

    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  const connectSocket = () => {
    if (!id && !slug) {
      navigate('/404', { replace: true });
    }

    socket.emit(ChatSocketEvent.joinChat, id ? { roomId: id } : { slug });
  };

  const clearPage = () => {
    setPageBlock(false);
    setPage(1);
    loadMessages();
  };

  const sendMessage = async (content: string, translateTo?: Language) => {
    setLoading(true);

    socket.emit(ChatSocketEvent.sendMessage, {
      roomId: id,
      slug,
      message: content,
      translateTo,
    });

    setLoading(false);
  };

  const loadMessages = async (add = false, hardPage?: number) => {
    setLoading(true);

    const result = await chatsService.getChatMessages({
      slug,
      roomId: id,
      page: hardPage ?? page,
      perPage: 10,
    });

    if (!result) {
      setLoading(false);
      return;
    }

    if (!result.length && add) {
      setPageBlock(true);
    }

    setMessages(add ? [...result.reverse(), ...messages] : result.reverse());

    if (!add) {
      setTimeout(() => scrollMessages(), 1000);
    }

    setTimeout(() => setLoading(false), 1200);
  };

  const deleteMessage = async (messageId: string) => {
    setLoading(true);

    const result = await shoutboxService.deleteMessage(messageId);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    clearPage();
    loadMessages(false, 1);

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  };

  const translateMessage = async (messageId: string) => {
    if (!slug) return;

    setLoading(true);

    const result = await chatsService.translateMessage(
      slug,
      messageId,
      currentLang,
    );

    if (!result || !result.translated) {
      setToast(
        toastAlertFactory(t({ phrase: 'Operation failed.' }), 'error', true),
      );
      setLoading(false);
      return;
    }

    setMessages(
      messages.map((message: ChatMessage) => {
        if (message.id === messageId) {
          return {
            ...message,
            content: result.translated,
          };
        }

        return message;
      }),
    );

    setLoading(false);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    loadMessages().then(() => setTimeout(() => scrollMessages(), 1000));
  }, []);

  useEffect(() => {
    if (page !== 1) {
      loadMessages(true);
    }
  }, [page]);

  useEffect(() => {
    connectSocket();

    socket.on(ChatSocketEvent.sendMessage, (_message: ChatMessage) => {
      clearPage();
    });

    socket.on(ChatSocketEvent.disconnect, (reason: string) => {
      if (reason === 'io server disconnect') {
        socket.connect();
      }
    });

    return () => {
      socket.off(ChatSocketEvent.sendMessage);
    };
  }, []);

  return (
    <Grid container sx={{ paddingTop: '20px' }}>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>
      <Grid
        sx={{ height: '270px', overflowY: 'scroll' }}
        id="chatMessages"
        item
        xs={12}
      >
        <ChatMessagesComponent
          messages={messages}
          pageBlocked={pageBlock}
          onLoadMore={loadMore}
          loading={loading}
          chatRoomSlug={slug}
          onDelete={deleteMessage}
          onTranslate={translateMessage}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '20px' }}>
        <ChatInputComponent
          onSendMessage={sendMessage}
          loading={loading}
          chatSlug={slug}
        />
      </Grid>
    </Grid>
  );
};
