import { AccountService } from '../app/services/account.service';
import { AuthService } from '../app/services/auth.service';
import { ChatsService } from '../app/services/chats.service';
import { LanguageService } from '../app/services/language.service';
import { ProjectFilesService } from '../app/services/project-files.service';
import { ProjectsService } from '../app/services/projects.service';
import { ShoutboxService } from '../app/services/shoutbox.service';
import { UsersService } from '../app/services/users.service';
import { WcagService } from '../app/services/wcag.service';
import { ApiHttpClient } from '../infrastructure/backend/backend-api';
import { CookiesService } from '../infrastructure/local-storage/cookie/cookies.service';
import { LocalStorageService } from '../infrastructure/local-storage/storage/local-storage.service';

// infra
export const cookiesService = new CookiesService();
export const localStorageService = new LocalStorageService();
export const backendApi = ApiHttpClient;

// app
export const chatsService = new ChatsService(backendApi);
export const shoutboxService = new ShoutboxService(backendApi);
export const usersService = new UsersService(backendApi);
export const projectFilesService = new ProjectFilesService(backendApi);
export const projectsService = new ProjectsService(backendApi);
export const accountService = new AccountService(backendApi);
export const authService = new AuthService(
  accountService,
  backendApi,
  localStorageService,
);
export const languageService = new LanguageService(localStorageService);
export const wcagService = new WcagService(localStorageService);
