import * as Joi from 'joi';
import { imageMimeTypes } from '../../enums/mime-type.enum';

export type UploadProjectVersionDto = {
  name: string;
  imageBase64: string;
  mime: string;
  baseId?: string;
};

export const uploadProjectVersionValidationSchema = Joi.object({
  imageBase64: Joi.string().base64(),
  name: Joi.string().min(3).max(255),
  baseId: Joi.string().optional(),
  mime: Joi.string().valid(...imageMimeTypes),
});
