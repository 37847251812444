import {
  ArrowDownward,
  PhotoSizeSelectLarge,
  Star,
  StarBorder,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AlertColor, Grid, IconButton, Link, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { createElement, useEffect, useState } from 'react';
import { GetProjectFilesDto } from '../../../../app/dto/project/get-project-files.dto';
import { Project } from '../../../../app/models/project';
import { ProjectFile } from '../../../../app/models/project-file';
import { User } from '../../../../app/models/user';
import { trimDataTypeFromBase64 } from '../../../../app/utils/base64-converter';
import { isEmployeeRole } from '../../../../app/utils/role-checker';
import { t } from '../../../../app/utils/translator';
import {
  authService,
  projectFilesService,
  projectsService,
} from '../../../../bootstrap/di';
import { buttonSx, inputSx, linkSx } from '../../../../sx';
import {
  confirmationFactory,
  ConfirmComponent,
  ConfirmComponentProps,
} from '../../shared/ConfirmComponent';
import {
  ImageEditorComponent,
  ImageEditorConfig,
} from '../../image-editor/ImageEditorComponent';
import { toastAlertFactory, ToastComponent } from '../../shared/ToastComponent';
import './ProjectFilesComponent.css';

export type ProjectFilesComponentProps = {
  project: Project;
  onEditModeChange?: CallableFunction;
};

export const ProjectFilesComponent = ({
  project,
  onEditModeChange,
}: ProjectFilesComponentProps) => {
  const user = authService.getLoggedUser() as User;
  const [imgEditorConfig, setImgEditorConfig] = useState({
    opened: false,
    source: null,
    onSaveFunction: uploadNewVersion,
  } as ImageEditorConfig);
  const [loading, setLoading] = useState(false);
  const [fullImgMode, setFullImgMode] = useState(false);
  const [searchFileName, setSearchFileName] = useState('');
  const [page, setPage] = useState(1);
  const [pageBlock, setPageBlock] = useState(false);
  const [projectFiles, setProjectFiles] = useState([] as ProjectFile[]);
  const [toast, setToast] = useState(toastAlertFactory('', 'error', false));
  const [editedImage, setEditedImage] = useState(null);
  const [confirmation, setConfirmation] = useState(
    null as unknown as ConfirmComponentProps,
  );

  const handleOpenEditor = async (projectFile: ProjectFile) => {
    const image = await projectFilesService.getImage(
      projectFile.projectId,
      projectFile.id,
      true,
    );

    if (!image) {
      return;
    }

    if (onEditModeChange) {
      onEditModeChange(true);
    }

    setEditedImage({
      ...projectFile,
      image,
    } as any);

    const img = new Image();
    img.src = image as string;

    setImgEditorConfig({
      opened: true,
      source: img,
      onSaveFunction: uploadNewVersion,
    });
  };

  async function uploadNewVersion({ fullName, imageBase64, mimeType }: any) {
    setLoading(true);

    const result = await projectsService.uploadVersion(project?.id as string, {
      name: fullName,
      imageBase64: trimDataTypeFromBase64(imageBase64),
      mime: mimeType,
    });

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    setImgEditorConfig({
      opened: false,
      source: null,
      onSaveFunction: uploadNewVersion,
    });

    await loadProjectFiles(false);

    if (onEditModeChange) {
      onEditModeChange(false);
    }

    setToast(toastAlertFactory(t({ phrase: 'Success!' }), 'success', true));

    setLoading(false);
  }

  const handleQueryChange = (name: string) => {
    setPageBlock(false);
    setPage(1);
    setSearchFileName(name);
  };

  const getImage = async (projectFile: ProjectFile) => {
    const image = await projectFilesService.getImage(
      projectFile.projectId,
      projectFile.id,
      true,
    );

    return createElement('img', {
      src: image,
      className: 'projectFileImg',
    });
  };

  const loadProjectFiles = async (add = false) => {
    setLoading(true);
    const result = await projectFilesService.getAll(project.id, {
      name: searchFileName || undefined,
      page,
      perPage: 3,
    } as unknown as GetProjectFilesDto);

    if (!result) return;

    if (!result.length && add) {
      setPageBlock(true);
    }

    const resultWithImages = [];
    for (const projectFile of result) {
      resultWithImages.push({
        ...projectFile,
        image: await getImage(projectFile),
      });

      if (add) {
        setProjectFiles([...projectFiles, ...resultWithImages]);
        continue;
      }
      setProjectFiles(resultWithImages);
    }

    setLoading(false);
  };

  const deleteFile = async (projectFileId: string) => {
    setLoading(true);

    const result = await projectFilesService.delete(project.id, projectFileId);

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    await loadProjectFiles(false);

    setToast(
      toastAlertFactory(
        t({
          phrase: result.message ?? 'Success!',
        }),
        'success',
        true,
      ),
    );

    setLoading(false);
  };

  const toggleMarked = async (projectFileId: string) => {
    setLoading(true);

    const result = await projectFilesService.toggleMarked(
      project.id,
      projectFileId,
    );

    if (!result.success) {
      setToast(
        toastAlertFactory(
          t({ phrase: result.message ?? 'Operation failed.' }),
          'error',
          true,
        ),
      );
      setLoading(false);
      return;
    }

    await loadProjectFiles(false);

    setToast(
      toastAlertFactory(
        t({
          phrase: result.message ?? 'Success!',
        }),
        'success',
        true,
      ),
    );

    setLoading(false);

    setTimeout(() => window.location.reload(), 2000);
  };

  useEffect(() => {
    setPageBlock(false);
    setPage(1);
    loadProjectFiles();
  }, [searchFileName]);

  useEffect(() => {
    if (page !== 1) {
      loadProjectFiles(true);
    }
  }, [page]);

  return (
    <Box>
      <ToastComponent
        message={toast.message}
        type={toast.type as AlertColor}
        fire={toast.fire}
        id={toast.id}
      ></ToastComponent>

      {confirmation && (
        <ConfirmComponent
          message={confirmation.message}
          callbackFn={confirmation.callbackFn}
          fire={confirmation.fire}
          id={confirmation.id}
          callbackParams={confirmation.callbackParams}
        />
      )}

      {imgEditorConfig.opened && (
        <>
          {!!editedImage && (
            <Box sx={{ textAlign: 'right', marginTop: '-35px' }} className='zoomable'>
              <Link
                download={(editedImage as any).name}
                sx={linkSx}
                href={(editedImage as any).image}
              >
                {t({ phrase: 'Download' })}
              </Link>
            </Box>
          )}
          <ImageEditorComponent config={imgEditorConfig} />
        </>
      )}

      {!imgEditorConfig.opened && (
        <Box className='zoomable'>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label={t({ phrase: 'Search' })}
                name="name"
                sx={{ ...inputSx, marginBottom: '20px' }}
                defaultValue={searchFileName}
                onChange={(e) => {
                  if (e.target.value.length > 2 || !e.target.value) {
                    handleQueryChange(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right', paddingTop: '20px' }}>
              <IconButton
                title={t({ phrase: 'Change photos display mode' })}
                onClick={() => setFullImgMode(!fullImgMode)}
              >
                <PhotoSizeSelectLarge />
              </IconButton>
            </Grid>
          </Grid>

          {(!projectFiles || !projectFiles.length) && (
            <h2>{t({ phrase: 'No records' })}</h2>
          )}

          {projectFiles.map((projectFile: ProjectFile) => (
            <Grid
              container
              spacing={3}
              key={projectFile.id}
              sx={{
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                paddingY: '10px;',
                paddingRight: '30px',
              }}
            >
              <Grid item sm={12} md={fullImgMode ? 12 : 6}>
                <Box
                  className={fullImgMode ? 'fullImgMode' : ''}
                  onClick={() => {
                    handleOpenEditor(projectFile);
                  }}
                  sx={{ textAlign: 'center' }}
                >
                  {projectFile.image}
                </Box>
              </Grid>
              <Grid item sm={12} md={fullImgMode ? 12 : 6}>
                <div>
                  <strong>{t({ phrase: 'Name' })}:</strong> {projectFile.name}{' '}
                </div>
                <div>
                  <strong>{t({ phrase: 'Uploaded at' })}:</strong>{' '}
                  {projectFile.uploadedAt}
                </div>
                <div>
                  <strong>{t({ phrase: 'Uploaded by' })}:</strong>{' '}
                  {projectFile.uploader}
                </div>
                <div>
                  {isEmployeeRole(user.role) && (
                    <LoadingButton
                      loading={loading}
                      sx={{ ...buttonSx, marginY: '10px' }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setConfirmation(
                          confirmationFactory(
                            deleteFile,
                            true,
                            t({
                              phrase:
                                'Are you sure you want to continue? This operation cannot be undone.',
                            }),
                            [projectFile.id],
                          ),
                        );
                      }}
                    >
                      {t({ phrase: 'Delete' })}
                    </LoadingButton>
                  )}
                  <LoadingButton
                    title={t({
                      phrase: projectFile.marked
                        ? 'final version'
                        : 'not accepted',
                    })}
                    loading={loading}
                    onClick={() => {
                      setConfirmation(
                        confirmationFactory(
                          toggleMarked,
                          true,
                          t({
                            phrase: 'Are you sure you want to continue?',
                          }),
                          [projectFile.id],
                        ),
                      );
                    }}
                  >
                    {t({ phrase: 'Final version' })}{' '}
                    {projectFile.marked ? <Star /> : <StarBorder />}
                  </LoadingButton>
                </div>
              </Grid>
            </Grid>
          ))}

          <Box sx={{ textAlign: 'center' }}>
            {!pageBlock && projectFiles.length > 2 && (
              <LoadingButton
                loading={loading}
                onClick={() => setPage(page + 1)}
              >
                {t({ phrase: 'load more' })} <ArrowDownward />
              </LoadingButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
