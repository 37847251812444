import {
  ChevronLeft,
  Contrast,
  FormatSize,
  GTranslate,
  Logout,
  Menu,
} from '@mui/icons-material';
import {
  Box,
  Container,
  CssBaseline,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Role } from '../../../app/enums/role.enum';
import { authService, wcagService } from '../../../bootstrap/di';
import { appTheme, linkSx } from '../../../sx';
import { LogoWhiteComponent } from '../../components/logo/LogoWhiteComponent';
import { AppBar } from './AppBar';
import { Drawer } from './Drawer';
import { SideBar } from './SideBar';
import { LogoComponent } from '../../components/logo/LogoComponent';
import { Footer } from '../../components/shared/Footer';
import { LanguageComponent } from '../../components/shared/LanguageComponent';
import { t } from '../../../app/utils/translator';

type LoggedTemplateProps = {
  children: JSX.Element;
};

export const LoggedTemplate = ({ children }: LoggedTemplateProps) => {
  const loggedUser = authService.getLoggedUser();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleContrast = () => {
    wcagService.toggleContrastMode();
    window.location.reload();
  };

  const toggleFontSize = () => {
    wcagService.toggleIncreasedFontSize();
    window.location.reload();
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          color={wcagService.isContrastMode() ? 'secondary' : 'default'}
          open={open}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="white"
              noWrap
              sx={{ flexGrow: 1, padding: '10px 0px 5px; 0px;' }}
            >
              {wcagService.isContrastMode() ? (
                <LogoWhiteComponent />
              ) : (
                <LogoComponent />
              )}
            </Typography>
            <IconButton color="inherit">
              <Link href="/logout" sx={linkSx}>
                <Logout />
              </Link>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeft />
            </IconButton>
          </Toolbar>
          <List component="nav" sx={{ paddingTop: '25px' }}>
            {SideBar(loggedUser?.role as Role)}
            <hr />
            <ListItemButton>
              <ListItemIcon
                onClick={() => {
                  !open && toggleDrawer();
                }}
              >
                <GTranslate />
              </ListItemIcon>
              <ListItemText>{open && <LanguageComponent />}</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={toggleContrast}>
              <ListItemIcon>
                <Contrast />
              </ListItemIcon>
              <ListItemText primary={t({ phrase: 'Adjust contrast' })} />
            </ListItemButton>
            <ListItemButton onClick={toggleFontSize}>
              <ListItemIcon>
                <FormatSize />
              </ListItemIcon>
              <ListItemText primary={t({ phrase: 'Adjust size' })} />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              !wcagService.isContrastMode()
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
            <Footer sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
