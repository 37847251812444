import { Contrast, FormatSize } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { t } from '../../../app/utils/translator';
import { wcagService } from '../../../bootstrap/di';

export const ContrastComponent = () => {
  const toggleContrast = () => {
    wcagService.toggleContrastMode();
    window.location.reload();
  };

  const toggleFontSize = () => {
    wcagService.toggleIncreasedFontSize();
    window.location.reload();
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <IconButton
        title={t({ phrase: 'Change contrast' })}
        onClick={toggleContrast}
      >
        <Contrast />
      </IconButton>
      <IconButton
        title={t({ phrase: 'Change font size' })}
        onClick={toggleFontSize}
      >
        <FormatSize />
      </IconButton>
    </Box>
  );
};
