export const imgEditContrastCss = `
.FIE_root {
    background: black !important;
  }
  
  .SfxInput-root {
    background: black !important;
  }
  
  .SfxPopupContent-Label {
    background: black !important;
    color: white !important;
  }
  
  .SfxModal-root {
    background: black !important;
    color: white;
  }
  
  .SfxModalTitle-LabelPrimary {
    color: white;
  }
  
  .FIE_save-resize-wrapper {
    display: none;
  }
  
  .FIE_annotation-option-popup > div {
    background: black !important;
  }
  
  .FIE_canvas-node {
    background: black !important;
  }
  
  .SfxColorPicker-root {
    background: black !important;
  }
  
  .SfxModalTitle-LabelSecondary {
    color: white;
  }
  
  .FIE_annotation-controls-overlay {
    background: black !important;
  }
  
  button[color='link'],
  button[color='link']:hover {
    color: white;
  }
  
  .FIE_save-file-name-input > input {
    background: black;
    color: white;
    height: 22px;
    padding: 5px 10px;
  }
  
  .FIE_save-extension-selector > label {
    color: white;
  }
  
  .FIE_save-file-name-input {
    padding: 0px !important;
  }
  
  .SfxModalTitle-Close {
    color: white !important;
  }  
`;
