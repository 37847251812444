import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Role } from '../../../app/enums/role.enum';
import { t } from '../../../app/utils/translator';
import {
  Chat,
  Gesture,
  Group,
  GTranslate,
  ManageAccounts,
} from '@mui/icons-material';
import {
  isActiveRole,
  isAdmin,
  isEmployeeRole,
} from '../../../app/utils/role-checker';

export const SideBar = (role: Role) => (
  <React.Fragment>
    <ListItemButton href="/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={t({ phrase: 'Main page' })} />
    </ListItemButton>

    {isActiveRole(role) && (
      <ListItemButton href="/projects">
        <ListItemIcon>
          <Gesture />
        </ListItemIcon>
        <ListItemText primary={t({ phrase: 'Projects' })} />
      </ListItemButton>
    )}

    {isAdmin(role) && (
      <ListItemButton href="/users">
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary={t({ phrase: 'Users' })} />
      </ListItemButton>
    )}

    {isEmployeeRole(role) && (
      <ListItemButton href="/shoutbox">
        <ListItemIcon>
          <Chat />
        </ListItemIcon>
        <ListItemText primary={t({ phrase: 'Shoutbox' })} />
      </ListItemButton>
    )}

    {isActiveRole(role) && (
      <ListItemButton href="/account">
        <ListItemIcon>
          <ManageAccounts />
        </ListItemIcon>
        <ListItemText primary={t({ phrase: 'Account' })} />
      </ListItemButton>
    )}
  </React.Fragment>
);
