import { Breadcrumbs, Grid, Link } from '@mui/material';
import { User } from '../../../app/models/user';
import { t } from '../../../app/utils/translator';
import { authService } from '../../../bootstrap/di';
import { DashboardSectionComponent } from '../../components/shared/DashboardSectionComponent';
import React from 'react';
import { EditAccountInfoComponent } from '../../components/account/EditAccountInfoComponent';
import { ChangeAccountPasswordComponent } from '../../components/account/ChangeAccountPasswordComponent';

export const AccountPage = () => {
  const loggedUser = authService.getLoggedUser() as User;

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: '15px' }}>
          <Link underline="hover" color="inherit" href="/">
            Aleso
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t({ phrase: 'Dashboard' })}
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            {t({ phrase: 'Your account' })}
          </Link>
        </Breadcrumbs>
      </div>
      <Grid container spacing={3} className='zoomable'>
        <Grid item xs={12} md={6} lg={6}>
          <DashboardSectionComponent
            title={t({ phrase: 'Edit account' })}
            minHeight={450}
          >
            <EditAccountInfoComponent user={loggedUser} />
          </DashboardSectionComponent>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <DashboardSectionComponent
            title={t({ phrase: 'Change your password' })}
            minHeight={450}
          >
            <ChangeAccountPasswordComponent />
          </DashboardSectionComponent>
        </Grid>
      </Grid>
    </>
  );
};
