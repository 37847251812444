import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button, Paper } from '@mui/material';
import { primaryButtonSx } from '../../../sx';

export type DashboardSectionComponentProps = {
  children: JSX.Element;
  title?: string;
  action?: {
    text: string;
    href?: string;
  };
  minHeight?: number;
};

export const DashboardSectionComponent = ({
  children,
  title,
  action,
  minHeight,
}: DashboardSectionComponentProps) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: minHeight ?? 300,
        overflowX: 'scroll',
        borderRadius: '0px',
      }}
    >
      <React.Fragment>
        {title && (
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {title}
          </Typography>
        )}
        {children}
        <div>
          {action && (
            <Link href={action.href ?? '#'} sx={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                sx={{ ...primaryButtonSx, padding: '5px 15px;' }}
              >
                {action.text}
              </Button>
            </Link>
          )}
        </div>
      </React.Fragment>
    </Paper>
  );
};
