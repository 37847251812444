import { Link, Typography } from '@mui/material';
import React from 'react';

export const Copyrights = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      ALESO Łukasz Sum {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
